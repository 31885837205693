import DropdownTreeSelect from "react-dropdown-tree-select";
import "../../node_modules/react-dropdown-tree-select/dist/styles.css";
import "../styles/MultiSelect.scss";
import { ReactSelectInterface } from "../interfaces/reactSelect";
import { useEffect, useMemo, useState } from "react";

const MultiLevelSelect = (props: ReactSelectInterface) => {
  const [linkList, setLinkList] = useState(props.links);
  useEffect(() => {
    setLinkList(props.links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (currentNode: any, selectedNodes: any) => {
    if (selectedNodes[0] !== undefined) {
      const value = currentNode?.value;
      props.handleCourseFileAndLinkSelection(value);
    } else {
      props.handleCourseFileAndLinkSelection("");
      //do nothing
    }
  };

  const dropdown = useMemo(() => {
    const Dropdown = DropdownTreeSelect as any;
    return (
      <Dropdown
        texts={{ placeholder: `Choose course ${props.type}...` }}
        data={linkList}
        onChange={onChange}
        mode="radioSelect"
        className="multilevel-select"
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkList]);

  return <>{dropdown}</>;
};

export default MultiLevelSelect;
