/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import buildDate from "./buildDate";
import iconArray from "./ActivityIconPack";
import {
  CourseActivityPanelItem,
  IconStyleInterface,
} from "../interfaces/CourseActivity_Interfaces";
import getClickableLink from "./getCLickableLink";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    // padding: "20px",
    fontSize: theme.typography.pxToRem(12),
    border: "1.5px solid #50247A",
  },
}));
const iconStyle: IconStyleInterface = {
  color: "black",
  stroke: "black",
  height: "30px",
  width: "30px",
  fill: "none",
};
const importantIconStyle: IconStyleInterface = {
  color: "#FF0000",
  stroke: "#FF0000",
  fill: "none",
  height: "30px",
  width: "30px",
};

const TooltipContents: FC<{ ActivityItem: CourseActivityPanelItem }> = (
  ActivityItem,
) => {
  return (
    <div className="tooltip_container">
      <div className="row activity-row">
        <div className="col-6 col-title font-weight-bold">Activity Name</div>
        <div className="col-6 desc_font">
          {ActivityItem.ActivityItem.activity_name}
        </div>
      </div>
      {ActivityItem.ActivityItem?.due_date ? (
        <div className="row activity-row">
          <div className="col-6 col-title font-weight-bold">Due Date</div>
          <div className="col-6 desc_font">
            {buildDate(new Date(ActivityItem.ActivityItem.due_date))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {ActivityItem.ActivityItem.weighting ? (
        <div className="row activity-row">
          <div className="col-6 col-title font-weight-bold">Weighting</div>
          <div className="col-6 desc_font">{ActivityItem.ActivityItem.weighting}</div>
        </div>
      ) : (
        <></>
      )}
      {ActivityItem.ActivityItem.link ? (
        <div className="row activity-row"
        >
          <div className="col-6 col-title font-weight-bold">Link</div>
          <div className="col-6 desc_font">
            <a
              href={getClickableLink(ActivityItem.ActivityItem.link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ActivityItem.ActivityItem.link}
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="row activity-row">
        <div className="col-6 col-title font-weight-bold">Description</div>
        <div className="col-6 desc_font">
          {ActivityItem.ActivityItem.description}
        </div>
      </div>
    </div>
  );
};

const ActivityTimeline: FC<any> = (activityTimeline) => {
  const [courseActivityList, setCourseActivityList] = useState<any>([]);
  useEffect(() => {
    generateActivityBlocks(activityTimeline);
  }, [activityTimeline]);
  let generateActivityBlocks = (activityTimeline: any) => {
    let ArrayList = activityTimeline?.activityTimeline?.Course_Activity_Panel;
    if (!ArrayList) {
      return;
    }
    ArrayList.sort((a: any, b: any) => {
      return a.panel_id - b.panel_id;
    });
    let tempListof6 = [];

    for (var i = 0; i < ArrayList.length; i++) {
      tempListof6.push(
        <li className="parent_weak_box" key={i}>
          <div className="weak__box">
            <div className="topic">{ArrayList[i].panel_name}</div>
            <div className="box" id={`panel${i}`}>
              {ArrayList[i].Course_Activity_Panel_Item.sort(
                (a: CourseActivityPanelItem, b: CourseActivityPanelItem) =>
                  a.panel_id! > b.panel_id!
                    ? 1
                    : b.panel_id! > a.panel_id!
                      ? -1
                      : 0,
              ).map((ActivityItem: CourseActivityPanelItem, index: number) => {
                return (
                  <>
                    {iconArray.map((icon) => {
                      if (ActivityItem.activity_type === icon.name) {
                        return (
                          <HtmlTooltip enterTouchDelay={0}
                            title={
                              <React.Fragment>
                                <TooltipContents ActivityItem={ActivityItem} />
                              </React.Fragment>
                            }
                          >
                            <div
                              tabIndex={0}
                              className="svg"
                              style={
                                ActivityItem.is_important
                                  ? importantIconStyle
                                  : iconStyle
                              }
                            >
                              <icon.iconName />
                            </div>
                          </HtmlTooltip>
                        );
                      }
                    })}
                  </>
                );
              })}
            </div>
          </div>
        </li>,
      );
    }
    setCourseActivityList(tempListof6);
  };

  return (
    <div role={"main"}>
      <ul className="outer__box" data-testid="activity-timeline">
        {courseActivityList}
      </ul>
    </div>
  );
};

export default ActivityTimeline;
