/* eslint-disable react-hooks/exhaustive-deps */
import {
  Panel,
  Panel_Item,
  Panel_Item_Task,
} from "../interfaces/Learning_Pathway_Interfaces";
import React, { FC, useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import AlertWindow from "./Snackbar";
import axios from "../axios/config";
import moment from "moment";
import getClickableLink from "./getCLickableLink";
import { useRef } from "react";
import { getSession } from "../utilities/querySession";
import { ReactComponent as InvalidLink } from "../pages/icons/invalidLink.svg";

const CarouselPanelItemTask: FC<{
  panelItemTask: Panel_Item_Task;
  key?: number;
  user?: any;
  tabAccessStatus: boolean;
  isImported: boolean;
}> = ({ panelItemTask, user, tabAccessStatus, isImported }) => {
  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const [popUpAlert, setPopUpAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });
  const [isTaskComplete, setIsTaskComplete] = useState<boolean>(false);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const getProgressData = async () => {
    try {
      const data = (
        await axios.get(
          `/api/learner/tasks?&panel_item_task_id=${panelItemTask.panel_item_task_id}&user_id=${user.user_id}`,
          {
            headers: {
              Authorization: getSession("token"),
            },
          },
        )
      ).data;
      setIsTaskComplete(data.status);
    } catch (e) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `error`,
      });
      setPopUpAlertOpen(true);
    }
  };
  useEffect(() => {
    getProgressData();
  }, []);

  const updateProgress = async (event: any) => {
    try {
      const progressData = {
        status: checkboxRef.current!.checked,
        user_id: user.user_id,
        panel_item_task_id: panelItemTask.panel_item_task_id,
      };
      await axios.put(`/api/learner/tasks`, progressData, {
        headers: {
          Authorization: getSession("token"),
        },
      });

      getProgressData();
    } catch (e) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `error`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setPopUpAlertOpen(false);
  };

  return (
    <div className="align-items-center item-container d-flex">
      <AlertWindow
        popUpAlertOpen={popUpAlertOpen}
        handleAlertClose={handleAlertClose}
        popUpAlert={popUpAlert}
      />
      <div className="item-title form-check p-0 m-1">
        <label className="container">
          <input
            ref={checkboxRef}
            tabIndex={tabAccessStatus ? 0 : -1}
            type="checkbox"
            checked={isTaskComplete}
            className="form-check-input"
            onClick={(event) => updateProgress(event)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                updateProgress(event);
              }
            }}
          />
          <span
            tabIndex={tabAccessStatus ? 0 : -1}
            className="cl_checkmark"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                checkboxRef.current!.checked = !isTaskComplete ?? true;
                updateProgress(event);
              }
            }}
          ></span>
          <div className="week_card-text">
            <label className="week_card-text card-text">
              <div className="form-check-label">
                {panelItemTask.link !== "" ? (
                  <div className="link-section">
                  <a
                    tabIndex={tabAccessStatus ? 0 : -1}
                    href={getClickableLink(panelItemTask.link)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {panelItemTask.task_name}
                  </a>
                  {isImported && !panelItemTask.is_valid_link && <InvalidLink className="invalid_icon_size" />}
                  </div>
                ) : (
                  panelItemTask.task_name
                )}
              </div>
            </label>
          </div>
        </label>
      </div>
    </div>
  );
};

const CarouselPanelItem: FC<{
  panelItem: Panel_Item;
  user: string | undefined;
  tabAccessStatus: boolean;
  displayCalendar: boolean;
  isImported: boolean;
}> = ({ panelItem, user, tabAccessStatus, displayCalendar, isImported }) => {
  return (
    <div className={displayCalendar ? "todo_border" : "todo_border_nochange"}>
      <div className="todo_item">
        <label className="m-1 todo_itemlabel">
          <div className="week_card-text">
            <label className="week_card-text card-text">
              <strong className="heading_weekcard_learners">
                {panelItem.panel_item_name}
              </strong>
            </label>
          </div>
        </label>
      </div>
      {panelItem.panel_item_tasks
        ?.sort((a: any, b: any) =>
          a.panel_item_task_id! > b.panel_item_task_id!
            ? 1
            : b.panel_item_task_id! > a.panel_item_task_id!
            ? -1
            : 0,
        )
        .map((panelItemTask: any, index: number) => (
          <CarouselPanelItemTask
            panelItemTask={panelItemTask}
            key={index}
            user={user}
            tabAccessStatus={tabAccessStatus}
            isImported={isImported}
          />
        ))}
    </div>
  );
};

const CarouselPanel: FC<{
  panel: Panel;
  user?: any;
  learningJourney?: any;
  endDate?: Date;
  selectedPanel?: number;
}> = ({ panel, user, learningJourney, endDate, selectedPanel }) => {
  const sDate = moment(panel.panel_week_date).format("DD MMM YYYY");
  const [eDate, updateEDate] = useState<string | undefined>();
  const [tabAccess, setTabAccess] = useState<boolean>(false);
  useEffect(() => {
    if (endDate !== undefined) {
      updateEDate(moment(endDate).format("DD MMM YYYY"));
    } else {
      updateEDate(undefined);
    }
  }, [endDate]);

  useEffect(() => {
    setTabAccess(panel.panel_order === selectedPanel);
  }, [selectedPanel]);

  const headerTextColor =
    panel.panel_colour === "#FBB800" ? "#000000" : "#ffffff";

  return (
    <div
      className="ca_container"
      role={"main"}
      data-testid="learningjourney-panel"
    >
      <div
        className="week_card card w2"
        style={{
          borderColor: `${panel.panel_colour}`,
          backgroundColor: `${panel.panel_colour}`,
          borderWidth: "2px",
          borderStyle: "solid",
          height: "500px",
        }}
      >
        <span
          className="week_card-header card-header w2"
          style={{
            color: headerTextColor,
          }}
        >
          {panel.panel_name}
        </span>
        <div
          className={
            learningJourney.display_calender
              ? "week_card-body card-body"
              : "week_card-body card-body border-bottom"
          }
        >
          <form name="to-do-form" className="todo__form">
            {panel.panel_items
              ?.sort((a: any, b: any) =>
                a.panel_item_id! > b.panel_item_id!
                  ? 1
                  : b.panel_item_id! > a.panel_item_id!
                  ? -1
                  : 0,
              )
              .map((panelItem: any, index: number) => (
                <CarouselPanelItem
                  panelItem={panelItem}
                  key={index}
                  user={user}
                  displayCalendar={learningJourney.display_calender}
                  tabAccessStatus={tabAccess}
                  isImported={learningJourney.is_imported}
                />
              ))}
          </form>
        </div>
        <div
          className={
            learningJourney.display_calender ? "calender_container" : "hidden"
          }
        >
          <p className="date_panel">
            {eDate ? `${sDate} to ${eDate}` : `${sDate}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarouselPanel;
