/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { carouselProps } from "../interfaces/assessment";
import { ReactComponent as EditIcon } from "../pages/icons/editInstructorCarousel.svg";
import { ReactComponent as SaveIcon } from "../pages/icons/saveInstructorCarousel.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x-circle.svg";
import { ReactComponent as DeleteIcon } from "../pages/icons/deleteIcon.svg";
import { ReactComponent as InvalidLink } from "../pages/icons/invalidLink.svg";
import {
  Properties,
  Panel_Item,
  Panel_Item_Task,
  Panel_Item_API_Data,
} from "../interfaces/Learning_Pathway_Interfaces";
import { DialogConfirmation } from "../pages/Instructor/dialog";
import axios from "../axios/config";
import InsertLinkModal from "./insertLinkModal";
import { CircularProgress, Stack } from "@mui/material";
import getClickableLink from "./getCLickableLink";

import { getSession } from "../utilities/querySession";

const getConfigurableProps = () => ({
  autoPlay: false,
  showThumbs: false,
  showIndicators: false,
});
interface InputProps {
  content: Panel_Item;
  onActive: Function;
  onChange: Function;
  onDelete: Function;
  showError: Function;
  showSavetoProceedError: Function;
  showRefreshError: Function;
  inactive: boolean;
  tabIndexStatus: boolean;
}
interface InputItemProps {
  content: Panel_Item_Task;
  onActive: Function;
  handleModal: Function;
  insertLink: string;
  appliedClick: number;
  onChange: Function;
  onDelete: Function;
  showError: Function;
  showSavetoProceedError: Function;
  showRefreshError: Function;
  insertLinkActive: boolean;
  isChloeImported: boolean;
  tabIndexStatus: boolean;
  inactive: boolean;
  linkCategory: string;
}
const InputContainer: FC<InputProps> = (props) => {
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [input, setInput] = useState<string>(
    props.content.panel_item_name ?? "",
  );
  const inputConfig = useRef<HTMLTextAreaElement>(null);
  const inputContainer = useRef<HTMLDivElement>(null);
  const [tempInput, setTempInput] = useState<string>("");
  const [panelItemDat, setPanelItemDat] = useState<Panel_Item>(props.content);
  const [loading, setLoading] = useState<boolean>(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const parentInputContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInput(props.content.panel_item_name ?? "");
    setPanelItemDat(props.content);
  }, [props.content]);

  useEffect(() => {
    const end = inputConfig.current?.value.length ?? 0;
    if (!isDisabled) {
      inputConfig.current?.focus();
      inputConfig.current?.setSelectionRange(end, end);
      inputContainer.current?.classList.add("border_");
      document.getElementsByTagName("body")[0].classList.add("ptr_none");
    } else {
      inputContainer.current?.classList.remove("border_");
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
    }
    function handleClickOutside(event: any) {
      if (!parentInputContainer.current?.contains(event.target)) {
        if (isDisabled) {
          inputConfig.current?.blur();
        } else {
          event.preventDefault();
          inputConfig.current?.focus();
          props.showSavetoProceedError();
          document.getElementsByTagName("body")[0].classList.add("ptr_none");
        }
        const textAreaInput: any = document.getElementById(
          "sub-heading-item-text-area",
        );
        if (textAreaInput) {
          document.getElementsByTagName("body")[0].classList.add("ptr_none");
          textAreaInput?.blur();
        } else {
          textAreaInput?.focus();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDisabled]);
  const updateSubHeading = async () => {
    setLoading(true);
    const data: Panel_Item_API_Data = {
      panel_item_name: inputConfig.current?.value.trim() ?? "",
      status: true,
      panel_item_id: panelItemDat.panel_item_id,
    };
    try {
      const panelItemUpdateStatus = (
        await axios.put(`/api/instructor/updatePanelItem`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (panelItemUpdateStatus[0] === 1) {
        props.onActive(false);
        props.onChange(props.content, input.trim());
        setDisabled(true);
        setLoading(false);
      } else {
        setLoading(false);
        setDisabled(true);
        props.onActive(false);
        props.showRefreshError();
      }
    } catch (err) {
      props.showError();
      setLoading(false);
    }
  };

  const handlePanelClose = async (result: Boolean) => {
    if (result) {
      setLoading(true);
      try {
        await deletePanelItem();
        props.onDelete(true, props.content.panel_item_id);
        setDisabled(true);
        setLoading(false);
      } catch (e) {
        props.showError();
        setLoading(false);
      }
    }
    setOpenPanel(false);
  };

  const deletePanelItem = async () => {
    await axios.delete(
      `/api/instructor/deletePanelItem?panel_item_id=${props.content.panel_item_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      }
    );
  };

  return (
    <div
      ref={inputContainer}
      className="sub-head-title"
      tabIndex={props.tabIndexStatus? 0:-1}
    >
      <DialogConfirmation
        title={popUpTitle}
        description={"Are you sure you want to delete this sub-heading?"}
        handleClose={handlePanelClose}
        open={openPanel}
      />
      <div
        style={{ width: "100%", alignItems: "center" }}
        className={isDisabled ? "flex" : "hidden"}
      >
        <div
          className="subheading-text"
          onClick={() => {
            if (!props.inactive) {
              inputConfig.current?.focus();
              props.onActive(true);
              setTempInput(input);
              setDisabled(false);
            }
          }}
        >
          {input}
        </div>
        <EditIcon
          tabIndex={0}
          style={{ display: !isDisabled ? "none" : "" }}
          className="sub-head-edit"
          title="Edit"
          id={"" + props.content?.item_state_id!}
          onClick={() => {
            if (!props.inactive) {
              inputConfig.current?.focus();
              props.onActive(true);
              setTempInput(input);
              setDisabled(false);
            }
          }}
          onKeyDown={(e) => {
            if (!props.inactive && (e.key === "Enter" || e.key === "Space")) {
              inputConfig.current?.focus();
              props.onActive(true);
              setTempInput(input);
              setDisabled(false);
            }
          }}
        />
        <DeleteIcon
          tabIndex={0}
          style={{ display: !isDisabled ? "none" : "" }}
          className="sub-head-edit"
          title="Delete"
          onClick={() => setOpenPanel(true)}
          onKeyDown={(e) => {
            if (!props.inactive && (e.key === "Enter" || e.key === "Space")) {
              e.preventDefault();
              setOpenPanel(true);
            }
          }}
        />
      </div>
      <div
        style={{ width: "100%", alignItems: "center" }}
        className={isDisabled ? "hidden" : "flex"}
        ref={parentInputContainer}
      >
        <textarea
          ref={inputConfig}
          name={"learning__config-head_" + props.content?.item_state_id!}
          id={"learning__config-head_" + props.content?.item_state_id!}
          className="sub-head-heading form-control"
          autoComplete="off"
          value={input}
          rows={1}
          onInput={() => {
            if (inputConfig.current?.value !== undefined) {
              if (inputConfig.current?.value.replace(/\n/g, "").trim() !== "") {
                inputContainer.current?.classList.remove("error");
              }
              setInput(inputConfig.current?.value.replace(/\n/g, "") ?? "");
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !loading) {
              if (inputConfig.current?.value !== undefined) {
                if (inputConfig.current?.value.trim() !== "") {
                  updateSubHeading();
                } else {
                  inputConfig.current.value = inputConfig.current?.value.trim();
                  inputConfig.current?.focus();
                  inputContainer.current?.classList.add("error");
                }
              } else {
                inputConfig.current?.focus();
                inputContainer.current?.classList.add("error");
              }
            }
          }}
        />
        {!loading && (
          <SaveIcon
            style={{ display: isDisabled ? "none" : "" }}
            className="sub-head-save ptr_all"
            title="Save"
            id={`${props.content?.item_state_id!}`}
            onClick={() => {
              if (inputConfig.current?.value !== undefined) {
                if (inputConfig.current?.value.trim() !== "") {
                  updateSubHeading();
                } else {
                  inputConfig.current.value = inputConfig.current?.value.trim();
                  inputConfig.current?.focus();
                  inputContainer.current?.classList.add("error");
                }
              } else {
                inputConfig.current?.focus();
                inputContainer.current?.classList.add("error");
              }
            }}
          />
        )}
        {loading && (
          <Stack className="me-1" alignItems="center">
            <CircularProgress variant="indeterminate" size="20px" />
          </Stack>
        )}
        {!loading && (
          <CloseIcon
            tabIndex={0}
            style={{ display: isDisabled ? "none" : "" }}
            title="Cancel"
            className="m-1"
            onClick={() => {
              props.onActive(false);
              setDisabled(true);
              setInput(tempInput);
            }}
            onKeyDown={(e) => {
              if(e.key === 'Enter'){
                props.onActive(false);
                setDisabled(true);
                setInput(tempInput);                
              }
            }}
            fill="#000"
          />
        )}
      </div>
    </div>
  );
};

const InputContainerItem: FC<InputItemProps> = ({
  content,
  onActive,
  handleModal,
  insertLink,
  appliedClick,
  onChange,
  onDelete,
  showError,
  showSavetoProceedError,
  showRefreshError,
  insertLinkActive,
  isChloeImported,
  tabIndexStatus,
  inactive,
  linkCategory,
}) => {
  const [link, setLink] = useState<string | undefined>(content.link);
  const [insertingLink, setInsertLink] = useState<string | undefined>(
    content.link,
  );
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [textInput, setTextInput] = useState<string>(content.task_name);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [disableClickBlock, setDisableClickBlock] = useState<boolean>(false);
  const [prevTextInput, setPrevInput] = useState<string>();
  const parentAddItemContainerEdit = useRef<HTMLDivElement>(null);
  const textAreaInput = useRef<HTMLTextAreaElement>(null);
  const addItemContainer = useRef<HTMLDivElement>(null);
  const insertLinkSpan = useRef<HTMLSpanElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [isValidLink, setIsValidLink] = useState<boolean | undefined>(
    content.is_valid_link,
  );
  const [linkCat, setIsLinkCategory] = useState<string | undefined>(
    linkCategory || content.link_category,
  );

  useEffect(() => {
    setTextInput(content.task_name);
    setIsLinkCategory(content.link_category);
    setLink(content.link);
    setIsValidLink(content.is_valid_link);
  }, [content]);
  useEffect(() => {
    setInsertLink(insertLink);
    setDisableClickBlock(true);
  }, [insertLink, appliedClick]);

  useEffect(() => {
    const end = textAreaInput.current?.value.length ?? 0;
    if (!isDisabled) {
      textAreaInput.current?.focus();
      textAreaInput.current?.setSelectionRange(end, end);
      document.getElementsByTagName("body")[0].classList.add("ptr_none");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
    }
    function handleClickOutside(event: any) {
      if (
        !parentAddItemContainerEdit.current?.contains(event.target) &&
        !insertLinkActive
      ) {
        if (isDisabled) {
          textAreaInput.current?.blur();
        } else if (!disableClickBlock) {
          textAreaInput.current?.blur();
        } else {
          event.preventDefault();
          textAreaInput.current?.focus();
          showSavetoProceedError();
          textAreaInput.current?.setSelectionRange(end, end);
          document.getElementsByTagName("body")[0].classList.add("ptr_none");
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [disableClickBlock, isDisabled]);

  const removeLink = () => {
    setInsertLink("");
    textAreaInput.current?.value
      ? insertLinkSpan.current?.classList.remove("text-muted")
      : insertLinkSpan.current?.classList.add("text-muted");
  };

  const handleEditSubHeadingItem = async () => {
    if (textAreaInput.current?.value !== undefined) {
      if (textAreaInput.current?.value.replace(/\n/g, "").trim() !== "") {
        setLoading(true);
        const updated = await putUpdateSubHeadingItem(content);
        if (updated[0]) {
          onChange(content, textInput?.trim(),linkCategory,updated[1], insertingLink);
          setDisabled(true);
          setLink(insertingLink);
          if(insertingLink === ""){
            setIsLinkCategory("");
          }
          onActive(false);
          setLoading(false);
        }
      } else {
        textAreaInput.current.value = textAreaInput.current?.value.trim();
        textAreaInput.current?.focus();
        addItemContainer.current?.classList.add("error");
      }
    } else {
      textAreaInput.current?.focus();
      addItemContainer.current?.classList.add("error");
    }
  };
  const putUpdateSubHeadingItem = async (panel_item: Panel_Item_Task) => {
    const data: Panel_Item_Task = {
      panel_item_task_id: panel_item.panel_item_task_id,
      task_name: textAreaInput.current?.value.trim() ?? "",
      link: insertingLink,
      link_category: linkCat,
    };

    try {
      const panelItemTaskId = (
        await axios.put(`/api/instructor/updatePanelItemTask`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (panelItemTaskId.updateStatus[0] === 1) {
        setIsValidLink(panelItemTaskId.isValidLink);
        return [true,panelItemTaskId.isValidLink];
      } else {
        setDisabled(true);
        onActive(false);
        setLoading(false);
        showRefreshError();
        return [false,false];
      }
    } catch (err) {
      showError();
      return [false,false];
    }
  };

  const handlePanelClose = async (result: Boolean) => {
    if (result) {
      try {
        await deletePanelItemTask();
        onDelete(content.panel_item_task_id);
        setOpenPanel(false);
      } catch (e) {
        showError();
      }
    }
    setOpenPanel(false);
  };

  const deletePanelItemTask = async () => {
    await axios.delete(
      `/api/instructor/deletePanelItemTask?panel_item_task_id=${content.panel_item_task_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      }
    );
  };

  return (
    <>
      <DialogConfirmation
        title={popUpTitle}
        description={"Are you sure you want to delete this item?"}
        handleClose={handlePanelClose}
        open={openPanel}
      />
      <div
        className="align-items-center item-container"
        style={{ display: isDisabled ? "flex" : "none" }}
      >
        <div className="item-title form-check m-1 d-flex">
          <input
            type="checkbox"
            className="form-check-input sub-heading-item-checkbox"
            id="exampleCheck1"
            tabIndex={tabIndexStatus ? 0 : -1}
          />
          <small
            className="form-check-label"
            onClick={() => {
              if (document.activeElement === document.body) {
                onActive(true, link);
                setDisabled(false);
                setDisableClickBlock(true);
                setPrevInput(textInput);
                textAreaInput.current?.focus();
              }
            }}
          >
            {link !== "" ? (
              <a
                href={getClickableLink(link)}
                target="_blank"
                rel="noreferrer"
                tabIndex={tabIndexStatus ? 0 : -1}
              >
                {textInput}
              </a>
            ) : (
              textInput
            )}
          </small>
          {isChloeImported &&
            !isValidLink && <InvalidLink className="invalid_icon_size" />}
        </div>
        <EditIcon
          tabIndex={tabIndexStatus ? 0 : -1}
          style={{ display: !isDisabled ? "none" : "" }}
          className="sub-head-edit"
          height={15}
          title="Edit"
          id={"" + content?.panel_item_id!}
          onClick={() => {
            if (!inactive) {
              onActive(true, link);
              setDisabled(false);
              setDisableClickBlock(true);
              setPrevInput(textInput);
              textAreaInput.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (!inactive && (e.key === "Enter" || e.key === "Space")) {
              onActive(true, link);
              setDisabled(false);
              setDisableClickBlock(true);
              setPrevInput(textInput);
              textAreaInput.current?.focus();
            }
          }}
        />
        <DeleteIcon
          tabIndex={tabIndexStatus ? 0 : -1}
          style={{ display: !isDisabled ? "none" : "" }}
          className="sub-head-edit"
          height={18}
          title="Delete"
          onClick={() => {
            if (!inactive) {
              setOpenPanel(true);
            }
          }}
          onKeyDown={(e) => {
            if (!inactive && (e.key === "Enter" || e.key === "Space")) {
              setOpenPanel(true);
            }
          }}
        />
      </div>
      <div
        className="align-items-start ptr_all"
        style={{ display: isDisabled ? "none" : "flex" }}
        ref={parentAddItemContainerEdit}
      >
        <div
          className="m-1 panel-sub-heading-item-wrapper"
          ref={addItemContainer}
        >
          <textarea
            id="edit-sub-heading-item-text-area"
            className="form-control m-1"
            value={textInput}
            ref={textAreaInput}
            onInput={() => {
              if (textAreaInput.current?.value !== undefined) {
                if (
                  textAreaInput.current?.value.replace(/\n/g, "").trim() !== ""
                ) {
                  addItemContainer.current?.classList.remove("error");
                }
                textAreaInput.current.value =
                  textAreaInput.current.value.replace(/\n/g, "");
                textAreaInput.current?.value.trim() !== ""
                  ? insertLinkSpan.current?.classList.remove("text-muted")
                  : insertLinkSpan.current?.classList.add("text-muted");
              }
              setTextInput(textAreaInput.current?.value ?? "");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !loading) {
                handleEditSubHeadingItem();
              }
            }}
            rows={1}
          />
          <hr className="m-2" />
          <div className="d-flex align-items-start m-2">
            {insertingLink !== "" ? (
              <>
                <p
                  className="sub-heading-item-link link-wrapper"
                  tabIndex={tabIndexStatus ? 0 : -1}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setDisableClickBlock(false);
                      handleModal(true);
                    }
                  }}
                  onClick={() => {
                    setDisableClickBlock(false);
                    handleModal(true);
                  }}
                >
                  {insertingLink}
                </p>
                <CloseIcon
                  title="Cancel"
                  className="removeLink"
                  tabIndex={tabIndexStatus ? 0 : -1}
                  onClick={removeLink}
                  onKeyDown={(e) => {
                    e.key === "Enter" && removeLink();
                  }}
                  fill="#000"
                />
              </>
            ) : (
              <span
                ref={insertLinkSpan}
                className={""}
                tabIndex={tabIndexStatus ? 0 : -1}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      textAreaInput.current?.value !== undefined &&
                      textAreaInput.current.value !== ""
                    ) {
                      setDisableClickBlock(false);
                      handleModal(true);
                    }
                  }
                }}
                onClick={() => {
                  if (
                    textAreaInput.current?.value !== undefined &&
                    textAreaInput.current.value !== ""
                  ) {
                    setDisableClickBlock(false);
                    handleModal(true, true);
                  }
                }}
              >
                Insert Link
              </span>
            )}
          </div>
        </div>
        <div className="addItem_icons">
          {!loading && (
            <CloseIcon
              tabIndex={tabIndexStatus ? 0 : -1}
              title="Cancel"
              className="m-1 close-add-item"
              width={24}
              height={24}
              onClick={() => {
                setDisabled(true);
                setTextInput(prevTextInput ?? "");
                onActive(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  setDisabled(true);
                  setTextInput(prevTextInput ?? "");
                  onActive(false);
                }
              }}
              fill="#000"
            />
          )}
          {!loading && (
            <SaveIcon
              tabIndex={tabIndexStatus ? 0 : -1}
              title="Save"
              className="mt-3 ptr_all addItem_SaveIcon"
              fill="red"
              onClick={() => {
                handleEditSubHeadingItem();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  handleEditSubHeadingItem();
                }
              }}
            />
          )}
          {loading && (
            <Stack className="mt-1" alignItems="center">
              <CircularProgress variant="indeterminate" size="25px" />
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};

const InstructorCarousel: FC<carouselProps> = (panelProp) => {
  const [panelData, updatePanelData] = useState<Properties[]>(
    panelProp.panelProp.sort((a, b) =>
      a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0,
    ),
  );
  const [isAddingSubHead, setAddingSubHead] = useState<boolean>(false);
  const [isEditingSubhead, setEditingSubhead] = useState<boolean>(false);
  const [isDeletingSubhead, setDeletingSubhead] = useState<boolean>(false);
  const [activeSubHeadingItem, setActiveSubHeadingItem] = useState<string>("");
  const [subHeadings, setSubHeading] = useState<Panel_Item[]>([]);
  const [totalSubheadings, setTotalSubheadings] = useState<number>(-1);
  const [isAddingSubHeadItem, setAddingSubHeadItem] = useState<boolean>(false);
  const [subHeadItemBlock, setSubHeadItemBlock] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [apply, setApplied] = useState<number>(0);
  const [activeSubHeadingItemChild, setActiveSubHeadingItemChild] =
    useState<string>("");
  const [insertLink, setInsertLink] = useState("");
  const [insertLinkActive, setInsertLinkActive] = useState<boolean>(false);
  const [isEditingSubheadItem, setEditingSubheadItem] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const subHeadingInput = useRef<HTMLTextAreaElement>(null);
  const inputContainer = useRef<HTMLDivElement>(null);
  const inputConfig = useRef<HTMLButtonElement>(null);
  const textAreaInput = useRef<HTMLTextAreaElement>(null);
  const addItemContainer = useRef<HTMLDivElement>(null);
  const parentAddItemContainer = useRef<HTMLDivElement>(null);
  const insertLinkSpan = useRef<HTMLSpanElement>(null);
  const [selectedPanel, setSelectedPanel] = useState<number>(0);
  const [insertLinkCategory, setLinkCategory] =
    useState<string>("External link");

  useEffect(() => {
    const panel_item: Panel_Item[] = [];
    updatePanelData(
      panelProp.panelProp.sort((a, b) =>
        a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0,
      ),
    );
    if (panelProp.panels !== undefined) {
      let k = 0;
      for (let i = 0; i < panelProp.panels.length; i++) {
        if (
          panelProp.panels[i]?.panel_items !== undefined &&
          (panelProp.panels[i]?.panel_items as unknown as []).length  > 0
        ) {
          panelProp.panels[i]?.panel_items.sort((a: any, b: any) =>
            a.panel_item_id! > b.panel_item_id!
              ? 1
              : b.panel_item_id! > a.panel_item_id!
              ? -1
              : 0,
          );
          for (let j = 0; j < panelProp.panels[i]?.panel_items.length; j++) {
            const item: Panel_Item = panelProp.panels[i]?.panel_items[j];
            item.item_state_id = k++;
            panel_item.push(panelProp.panels[i]?.panel_items[j]);
          }
        }
      }
      // if (subHeadings.length === 0) {
      setTotalSubheadings(panel_item.length - 1);
      setSubHeading(panel_item);
      // }
    }

    resetPanelActions();
  }, [panelProp.panels, panelProp.panelProp]);

  useEffect(() => {
    if (isAddingSubHead) {
      subHeadingInput.current?.focus();
      document.getElementsByTagName("body")[0].classList.add("ptr_none");
    } else if (!isAddingSubHead) {
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
    }
    function handleClickOutside(event: any) {
      if (!inputContainer.current?.contains(event.target)) {
        if (!isAddingSubHead) {
          inputConfig.current?.blur();
          // document.getElementsByTagName("body")[0].classList.remove("ptr_none");
        } else {
          event.preventDefault();
          inputConfig.current?.focus();
          panelProp.showSavetoProceedError();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddingSubHead]);

  useEffect(() => {
    setSelectedPanel(panelProp.selectedPanel);
  }, [panelProp.selectedPanel]);
  useEffect(() => {
    if (subHeadItemBlock) {
      textAreaInput.current?.focus();
      document.getElementsByTagName("body")[0].classList.add("ptr_none");
    } else if (!subHeadItemBlock) {
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
    }
    function handleClickOutside(event: any) {
      if (
        !parentAddItemContainer.current?.contains(event.target) &&
        !insertLinkActive
      ) {
        if (!subHeadItemBlock) {
          textAreaInput.current?.blur();
          // document.getElementsByTagName("body")[0].classList.remove("ptr_none");
        } else {
          event.preventDefault();
          textAreaInput.current?.focus();
          panelProp.showSavetoProceedError();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [subHeadItemBlock, insertLinkActive]);

  const updateSubHeads = (panelItemId: number) => {
    let subHeads: Panel_Item[] = JSON.parse(JSON.stringify(subHeadings));
    let newSubHeads = subHeads.filter(function (subHead) {
      return subHead.panel_item_id !== panelItemId;
    });
    setSubHeading(newSubHeads);
    setTotalSubheadings(totalSubheadings - 1);
  };

  const updateSubItems = (panelItemTaskId: number) => {
    let subHeads: Panel_Item[] = JSON.parse(JSON.stringify(subHeadings));
    let newSubHeads: any = subHeads.map((heading) => {
      return {
        ...heading,
        panel_item_tasks: heading.panel_item_tasks?.filter(
          (panelItemTask) =>
            panelItemTask.panel_item_task_id !== panelItemTaskId
        ),
      };
    });
    setSubHeading(newSubHeads);
  };

  const resetPanelActions = () => {
    setAddingSubHead(false);
    setActiveSubHeadingItem("");
  };

  const handleRefreshError = () => {
    panelProp.showRefreshError();
  };

  const handleAddSubHeading = async (
    isAdd: boolean,
    itemId: string,
    panelId: number,
  ) => {
    const subHeading = subHeadingInput.current?.value;

    setActiveSubHeadingItem(itemId);
    if (!isAdd && subHeading !== "" && subHeading !== undefined) {
      setLoading(true);

      const contentItem: Panel_Item = {
        item_state_id: totalSubheadings + 1,
        panel_id: panelId,
        panel_item_name: subHeading ?? "",
        panel_item_tasks: [],
      };
      const myArray = Object.assign([], subHeadings);
      myArray.push(contentItem);
      const myArrayUpdated = await putSubHeading(panelId, myArray);
      if (myArrayUpdated !== undefined) {
        setSubHeading(myArrayUpdated);
        setTotalSubheadings((totalSubheadings) => {
          return totalSubheadings + 1;
        });
        setAddingSubHead(isAdd);
        setLoading(false);
        setActiveSubHeadingItem("");
      } else {
        setAddingSubHead(isAdd);
        setActiveSubHeadingItem("");
        setLoading(false);
        handleRefreshError();
      }
    } else {
      setAddingSubHead(isAdd);
    }
  };

  const resetAddItemPanelActions = () => {
    setAddingSubHeadItem(false);
    setSubHeadItemBlock(false);
    setActiveSubHeadingItemChild("");
  };

  const handleEditSubheading = (subhead: any, title: string) => {
    let subHeads: Panel_Item[] = JSON.parse(JSON.stringify(subHeadings));
    subHeads = subHeads.map((subheading: Panel_Item) => {
      if (subheading.panel_item_id === subhead.panel_item_id) {
        subheading.panel_item_name = title;
      }
      return subheading;
    });
    if (subHeads !== undefined) {
      setSubHeading(subHeads);
    }
  };

  const handleAddSubHeadingItem = async (
    isAdd: boolean,
    panelItemId: string,
    panelId: string,
  ) => {
    const subHeading = document.getElementById(
      "sub-heading-item-text-area",
    ) as HTMLTextAreaElement;
    const textInputValue = subHeading?.value;
    textAreaInput.current?.focus();
    if (!isAdd && textInputValue !== "" && textInputValue !== undefined) {
      setLoading(true);
      setActiveSubHeadingItemChild("sub-heading-item_" + panelItemId);
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
      let myArray: Panel_Item[] = JSON.parse(JSON.stringify(subHeadings));
      myArray = myArray.map((subHeading: Panel_Item) => {
        if (
          subHeading.panel_id === +panelId &&
          subHeading.panel_item_id === +panelItemId
        )
          subHeading.panel_item_tasks!.push({
            task_name: textInputValue,
            link: insertLink,
          });
        return subHeading;
      });

      const myArrayUpdated = await putSubHeadingItem(
        +panelId,
        +panelItemId,
        myArray,
      );
      if (myArrayUpdated !== undefined) {
        setSubHeading(myArrayUpdated);
        setAddingSubHeadItem(isAdd);
        setSubHeadItemBlock(isAdd);
        setLoading(false);
        setActiveSubHeadingItemChild("");
      } else {
        setAddingSubHeadItem(isAdd);
        setSubHeadItemBlock(isAdd);
        setLoading(false);
        setActiveSubHeadingItemChild("");
        handleRefreshError();
      }
    } else {
      setInsertLink("");
      setActiveSubHeadingItemChild(panelItemId);
      setAddingSubHeadItem(isAdd);
      setSubHeadItemBlock(isAdd);
    }
  };

  const handleEditSubHeadingItem = (
    task: any,
    title: string,
    linkCategory: string,
    isValidLink: boolean,
    link?: string,
  ) => {
    let subHeads: Panel_Item[] = JSON.parse(JSON.stringify(subHeadings));
    subHeads = subHeads.map((subHeading: Panel_Item) => {
      if (subHeading.panel_item_id === task.panel_item_id)
        subHeading.panel_item_tasks = subHeading.panel_item_tasks!.map(
          (item_task: any) => {
            console.log('Item Task', item_task);
            if (item_task.panel_item_task_id === task.panel_item_task_id) {
              item_task.task_name = title;
              item_task.link = link;
              item_task.is_valid_link = isValidLink;
              item_task.link_category = linkCategory;
            }
            return item_task;
          },
        );
      return subHeading;
    });
    console.log('Subheads: ', subHeads);
    setSubHeading(subHeads);
  };

  const handleModal = (show: boolean,
    ) => {
    setShow(true);
    setInsertLinkActive(true);
  };

  const handleModalClose = () => {
    setShow(false);
    setInsertLinkActive(false);
  }

  const applyLink = (link: string, linkCategory: string) => {
    setInsertLink(link);
    setLinkCategory(linkCategory);
    setApplied((apply) => (apply += 1));
    setShow(false);
  };

  const removeLink = () => {
    setInsertLink("");
    textAreaInput.current?.value
      ? insertLinkSpan.current?.classList.remove("text-muted")
      : insertLinkSpan.current?.classList.add("text-muted");
  };

  const putSubHeading = async (
    panelId: number,
    subHeadingsValue: Panel_Item[],
  ) => {
    const data: Panel_Item_API_Data = {
      panel_id: panelId!,
      panel_item_name: subHeadingInput.current?.value ?? "",
      status: false,
    };
    let subHeads: Panel_Item[] = subHeadingsValue;
    try {
      const panelItemId = (
        await axios.put(`/api/instructor/storePanelItem`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (panelItemId instanceof Array) {
        subHeads[totalSubheadings + 1].panel_item_id =
          panelItemId[0].panel_item_id;
      } else {
        subHeads[totalSubheadings + 1].panel_item_id =
          panelItemId.panel_item_id;
      }
      return subHeads;
    } catch (err) {
      console.warn("Panel Item not added !", err);
      return undefined;
    }
  };

  const putSubHeadingItem = async (
    panelId: number,
    panelItemId: number,
    subHeadingsValue: Panel_Item[],
  ) => {
    const data: Panel_Item_Task = {
      panel_item_id: panelItemId,
      task_name: textAreaInput.current?.value!,
      link: insertLink,
      link_category: insertLinkCategory,
    };

    const subHeads: Panel_Item[] = subHeadingsValue;
    try {
      const panelItemTaskId = (
        await axios.put(`/api/instructor/storePanelItemTask`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      const myArray = subHeads.map((subHeading: Panel_Item) => {
        if (
          subHeading.panel_id === panelId &&
          subHeading.panel_item_id === panelItemId!
        ) {
          const panelItemTasks = subHeading.panel_item_tasks!.map(
            (task: Panel_Item_Task) => {
              if (
                task.task_name === data.task_name &&
                task.panel_item_task_id === undefined
              ) {
                task = panelItemTaskId[0];
              }
              return task;
            },
          );
          subHeading.panel_item_tasks = panelItemTasks;
        }
        return subHeading;
      });
      return myArray;
    } catch (err) {
      console.warn("Panel Item not added !", err);
    }
  };

  const inactive: boolean = (isAddingSubHead || isEditingSubhead ||isAddingSubHeadItem || isEditingSubheadItem);

  const panels = panelData.map((panel: any, panel_index: number) => {
    return (
      <div className="ca_container" key={panel.id} data-index={panel_index}>
        <div
          className="week_card card carousel_card"
          style={{ border: `solid ${panel.color}` }}
        >
          <div
            className="week_card-header card-header"
            style={{ backgroundColor: panel.color, color: `#ffffff` }}
          >
            <span className="week_card-title">{panel.input}</span>
          </div>
          <div className="sub-heading m-2">
            {subHeadings.map(
              (content: any, sub_index: number) =>
                content.panel_id === panel.panel_id && (
                  <div className="m-2 mb-0" key={sub_index}>
                    <div
                      className={isAddingSubHeadItem ? "ptr_none m-1" : "m-1"}
                    >
                      <InputContainer
                        content={content}
                        tabIndexStatus={panel_index === selectedPanel}
                        onActive={(value: boolean) => {
                          setEditingSubhead(value);
                        }}
                        onChange={handleEditSubheading}
                        key={sub_index}
                        onDelete={(value: boolean, panelItemId: number) => {
                          setDeletingSubhead(value);
                          updateSubHeads(panelItemId);
                        }}
                        showError={panelProp.showError}
                        showSavetoProceedError={
                          panelProp.showSavetoProceedError
                        }
                        showRefreshError={handleRefreshError}
                        inactive={
                          isAddingSubHead ||
                          isEditingSubhead ||
                          isAddingSubHeadItem ||
                          isEditingSubheadItem
                        }
                      />
                    </div>

                    <div className="add-item m-1">
                      {content.panel_item_tasks
                        .sort((a: Panel_Item_Task, b: Panel_Item_Task) =>
                          a.panel_item_task_id! > b.panel_item_task_id!
                            ? 1
                            : b.panel_item_task_id! > a.panel_item_task_id!
                            ? -1
                            : 0,
                        )
                        .map((item: any, index_: number) => (
                          <InputContainerItem
                            tabIndexStatus={panel_index === selectedPanel}
                            key={panel_index + index_}
                            content={item}
                            onActive={(
                              isEditSubItem: boolean,
                              link?: string,
                            ) => {
                              setInsertLink(link ?? "");
                              setEditingSubheadItem(isEditSubItem);
                              if (isEditSubItem) {
                                document
                                  .querySelector(".selected .sub-heading")
                                  ?.classList.add("ptr_none");
                              } else {
                                document
                                  .querySelector(".selected .sub-heading")
                                  ?.classList.remove("ptr_none");
                              }
                            }}
                            handleModal={handleModal}
                            insertLink={insertLink}
                            appliedClick={apply}
                            onChange={handleEditSubHeadingItem}
                            onDelete={(panelItemTaskId: number) =>
                              updateSubItems(panelItemTaskId)
                            }
                            showError={panelProp.showError}
                            showSavetoProceedError={
                              panelProp.showSavetoProceedError
                            }
                            showRefreshError={handleRefreshError}
                            insertLinkActive={insertLinkActive}
                            isChloeImported={panelProp.isChloeImported}
                            inactive={
                              isAddingSubHead ||
                              isEditingSubhead ||
                              isAddingSubHeadItem ||
                              isEditingSubheadItem
                            }
                            linkCategory={insertLinkCategory}
                          />
                        ))}
                      {isAddingSubHeadItem &&
                      activeSubHeadingItemChild ===
                        "sub-heading-item_" + content.panel_item_id ? (
                        <div
                          className="d-flex align-items-start"
                          ref={parentAddItemContainer}
                        >
                          <div
                            className="m-1 panel-sub-heading-item-wrapper"
                            ref={addItemContainer}
                          >
                            <textarea
                              id="sub-heading-item-text-area"
                              className="form-control m-1 shadow-none"
                              ref={textAreaInput}
                              onInput={() => {
                                if (
                                  textAreaInput.current?.value !== undefined
                                ) {
                                  if (
                                    textAreaInput.current?.value
                                      .replace(/\n/g, "")
                                      .trim() !== ""
                                  ) {
                                    addItemContainer.current?.classList.remove(
                                      "error"
                                    );
                                  }
                                  textAreaInput.current.value =
                                    textAreaInput.current.value.replace(
                                      /\n/g,
                                      ""
                                    );
                                  textAreaInput.current.value.trim() !== ""
                                    ? insertLinkSpan.current?.classList.remove(
                                        "text-muted",
                                      )
                                    : insertLinkSpan.current?.classList.add(
                                        "text-muted",
                                      );
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && !loading) {
                                  if (
                                    textAreaInput.current?.value !== undefined
                                  ) {
                                    if (
                                      textAreaInput.current?.value
                                        .replace(/\n/g, "")
                                        .trim() !== ""
                                    ) {
                                      textAreaInput.current.value =
                                        textAreaInput.current?.value.trim();
                                      handleAddSubHeadingItem(
                                        false,
                                        content.panel_item_id,
                                        content.panel_id
                                      );
                                    } else {
                                      textAreaInput.current.value =
                                        textAreaInput.current?.value.trim();
                                      textAreaInput.current?.focus();
                                      addItemContainer.current?.classList.add(
                                        "error"
                                      );
                                    }
                                  } else {
                                    textAreaInput.current?.focus();
                                    addItemContainer.current?.classList.add(
                                      "error"
                                    );
                                  }
                                }
                              }}
                              rows={1}
                            />
                            <hr className="m-2" />
                            <div className="d-flex align-items-start m-2">
                              {isAddingSubHeadItem &&
                              activeSubHeadingItemChild ===
                                "sub-heading-item_" + content.panel_item_id &&
                              insertLink !== "" ? (
                                <>
                                  <p
                                    className="sub-heading-item-link link-wrapper"
                                    tabIndex={0}
                                    onKeyDown={(e)=>{(e.key === 'Enter') && handleModal(true)}}
                                    onClick={() => handleModal(true)}
                                  >
                                    {insertLink}
                                  </p>
                                  <CloseIcon
                                    tabIndex={0}
                                    title="Cancel"
                                    className="removeLink"
                                    onClick={removeLink}
                                    onKeyDown={(e)=>{(e.key==='Enter') && removeLink();}}
                                    fill="#000"
                                  />
                                </>
                              ) : (
                                <span
                                  ref={insertLinkSpan}
                                  tabIndex={0}
                                  className={
                                    textAreaInput.current?.value
                                      ? ""
                                      : "text-muted"
                                  }
                                  onKeyDown={(e)=> {
                                    if(e.key === 'Enter'){
                                      textAreaInput.current?.value &&
                                      handleModal(true);
                                    }
                                  }}
                                  onClick={() => {
                                    textAreaInput.current?.value &&
                                      handleModal(true);
                                  }}
                                >
                                  Insert Link
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="addItem_icons">
                            {!loading && (
                              <CloseIcon
                                tabIndex={
                                  panel_index === selectedPanel ? 0 : -1
                                }
                                title="Cancel"
                                className="m-1 close-add-item"
                                width={24}
                                height={24}
                                onClick={resetAddItemPanelActions}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.key === "Space")
                                    resetAddItemPanelActions();
                                }}
                                fill="#000"
                              />
                            )}

                            {!loading && (
                              <SaveIcon
                                tabIndex={
                                  panel_index === selectedPanel ? 0 : -1
                                }
                                title="Save"
                                className="mt-3 ptr_all"
                                fill="red"
                                width={24}
                                height={24}
                                onClick={() => {
                                  if (
                                    textAreaInput.current?.value !== undefined
                                  ) {
                                    if (
                                      textAreaInput.current?.value.trim() !== ""
                                    ) {
                                      textAreaInput.current.value =
                                        textAreaInput.current?.value.trim();
                                      handleAddSubHeadingItem(
                                        false,
                                        content.panel_item_id,
                                        content.panel_id,
                                      );
                                    } else {
                                      textAreaInput.current.value =
                                        textAreaInput.current?.value.trim();
                                      textAreaInput.current?.focus();
                                      addItemContainer.current?.classList.add(
                                        "error",
                                      );
                                    }
                                  } else {
                                    textAreaInput.current?.focus();
                                    addItemContainer.current?.classList.add(
                                      "error",
                                    );
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.key === "Space") {
                                    if (
                                      textAreaInput.current?.value !== undefined
                                    ) {
                                      if (
                                        textAreaInput.current?.value.trim() !==
                                        ""
                                      ) {
                                        textAreaInput.current.value =
                                          textAreaInput.current?.value.trim();
                                        handleAddSubHeadingItem(
                                          false,
                                          content.panel_item_id,
                                          content.panel_id,
                                        );
                                      } else {
                                        textAreaInput.current.value =
                                          textAreaInput.current?.value.trim();
                                        textAreaInput.current?.focus();
                                        addItemContainer.current?.classList.add(
                                          "error",
                                        );
                                      }
                                    } else {
                                      textAreaInput.current?.focus();
                                      addItemContainer.current?.classList.add(
                                        "error",
                                      );
                                    }
                                  }
                                }}
                              />
                            )}

                            {loading && (
                              <Stack className="" alignItems="center">
                                <CircularProgress
                                  variant="indeterminate"
                                  size="25px"
                                />
                              </Stack>
                            )}
                          </div>
                        </div>
                      ) : (
                        <button
                          title="Add item"
                          tabIndex={panel_index === selectedPanel ? 0 : -1}
                          className={
                            isAddingSubHead ||
                            isAddingSubHeadItem ||
                            isEditingSubhead
                              ? "btn add-sub-heading-btn m-1 mt-2 ptr_none"
                              : "btn add-sub-heading-btn m-1 mt-2"
                          }
                          onClick={() => {
                            if (!inactive) {
                              handleAddSubHeadingItem(
                                true,
                                "sub-heading-item_" + content.panel_item_id,
                                content.panel_id
                              );
                              textAreaInput.current?.focus();
                            }
                          }}
                        >
                          Add item
                        </button>
                      )}
                    </div>
                    <hr className="mt-2 mb-2" />
                  </div>
                ),
            )}
            {!isAddingSubHead ||
            activeSubHeadingItem !== "sub-heading_" + panel.id ? (
              <button
                title="Add Sub Heading"
                tabIndex={panel_index === selectedPanel ? 0 : -1}
                className={
                  isAddingSubHeadItem || isEditingSubhead
                    ? "btn add-sub-heading-btn ptr_none"
                    : "btn add-sub-heading-btn"
                }
                data-id={"sub-heading_" + panel.id}
                ref={inputConfig}
                onClick={() => {
                  if (!inactive) {
                    handleAddSubHeading(
                      true,
                      "sub-heading_" + panel.id,
                      panel.panel_id
                    );
                  }
                }}
              >
                Add Sub Heading
              </button>
            ) : (
              activeSubHeadingItem === "sub-heading_" + panel.id && (
                <div
                  className={`${
                    isAddingSubHead &&
                    activeSubHeadingItem === "sub-heading_" + panel.id
                      ? "panel-sub-wrapper m-2"
                      : "m-2"
                  }`}
                  ref={inputContainer}
                >
                  <textarea
                    ref={subHeadingInput}
                    className="form-control sub_heading__input "
                    autoComplete="off"
                    rows={1}
                    onInput={() => {
                      inputContainer.current?.classList.remove("error");
                      subHeadingInput.current!.value =
                        subHeadingInput.current?.value.replace(/\n/g, "") ?? "";
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !loading) {
                        e.preventDefault();
                        if (subHeadingInput.current?.value !== undefined) {
                          if (subHeadingInput.current?.value.trim() !== "") {
                            handleAddSubHeading(
                              false,
                              "sub-heading_" + panel.id,
                              panel.panel_id
                            );
                          } else {
                            subHeadingInput.current!.value =
                              subHeadingInput.current?.value.trim() ?? "";
                            subHeadingInput.current?.focus();
                            inputContainer.current?.classList.add("error");
                          }
                        } else {
                          subHeadingInput.current?.focus();
                          inputContainer.current?.classList.add("error");
                        }
                      }
                    }}
                  />
                  {!loading && (
                    <SaveIcon
                      tabIndex={panel_index === selectedPanel ? 0 : -1}
                      title="Save"
                      className="ptr_all"
                      fill="red"
                      onClick={async () => {
                        if (subHeadingInput.current?.value !== undefined) {
                          if (subHeadingInput.current?.value.trim() !== "") {
                            handleAddSubHeading(
                              false,
                              "sub-heading_" + panel.id,
                              panel.panel_id,
                            );
                          } else {
                            subHeadingInput.current.value =
                              subHeadingInput.current?.value.trim();
                            subHeadingInput.current?.focus();
                            inputContainer.current?.classList.add("error");
                          }
                        } else {
                          subHeadingInput.current?.focus();
                          inputContainer.current?.classList.add("error");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !loading) {
                          if (subHeadingInput.current?.value !== undefined) {
                            if (subHeadingInput.current?.value.trim() !== "") {
                              handleAddSubHeading(
                                false,
                                "sub-heading_" + panel.id,
                                panel.panel_id
                              );
                            } else {
                              subHeadingInput.current.value =
                                subHeadingInput.current?.value.trim();
                              subHeadingInput.current?.focus();
                              inputContainer.current?.classList.add("error");
                            }
                          } else {
                            subHeadingInput.current?.focus();
                            inputContainer.current?.classList.add("error");
                          }
                        }
                      }}
                    />
                  )}
                  {loading && (
                    <Stack alignItems="center">
                      <CircularProgress variant="indeterminate" size="20px" />
                    </Stack>
                  )}

                  {!loading && (
                    <CloseIcon
                      tabIndex={panel_index === selectedPanel ? 0 : -1}
                      title="Cancel"
                      className="m-1"
                      onClick={resetPanelActions}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          resetPanelActions();
                        }
                      }}
                      fill="#000"
                    />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <InsertLinkModal
        show={show}
        handleClose={handleModalClose}
        applyLink={applyLink}
        isChloeImported={panelProp.isChloeImported}
        onChangeChloeImported={panelProp.onChangeChloeImported}
      />
      <div
        onKeyDown={(e) => {
          const isActive =
            isAddingSubHead ||
            isEditingSubhead ||
            isAddingSubHeadItem ||
            isEditingSubheadItem;
          if (!isActive && e.key === "ArrowRight") {
            setSelectedPanel((selectedPanel) =>
              selectedPanel < panels.length - 1
                ? selectedPanel + 1
                : selectedPanel
            );
          } else if (!isActive && e.key === "ArrowLeft") {
            setSelectedPanel((selectedPanel) =>
              selectedPanel > 0 ? selectedPanel - 1 : selectedPanel
            );
          }
        }}
      >
        <Carousel
          width="100%"
          showStatus={false}
          selectedItem={selectedPanel}
          emulateTouch={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) => {
            const defStyle = { color: "white", cursor: "pointer" };
            const style = hasPrev
              ? { ...defStyle, color: "black", fill: "black" }
              : { ...defStyle, color: "grey", fill: "grey" };
            return (
              <div className="no_margin ca_prev">
                <svg
                  tabIndex={0}
                  style={style}
                  onClick={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel > 0 ? selectedPanel - 1 : selectedPanel
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      !inactive &&
                      (e.key === "Enter" || e.key === "Space")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel > 0 ? selectedPanel - 1 : selectedPanel
                      );
                    }
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="47"
                  height="47"
                  viewBox="0 0 47 47"
                >
                  <use style={style} />
                  <path
                    id="next"
                    d="M14.352,45.153A23.5,23.5,0,0,1,23.5,0,23.492,23.492,0,0,1,40.117,40.117a23.525,23.525,0,0,1-25.764,5.036Zm.933-41.1A21.167,21.167,0,1,0,23.5,2.4,21.017,21.017,0,0,0,15.286,4.056Zm1.5,28.724V14.22a1,1,0,0,1,1.507-.861l15.736,9.28a1,1,0,0,1,0,1.723l-15.736,9.28a1,1,0,0,1-1.507-.861Z"
                    transform="translate(47 47) rotate(180)"
                  />
                </svg>
                <p
                  style={style}
                  onClick={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel > 0 ? selectedPanel - 1 : selectedPanel
                      );
                    }
                  }}
                  onKeyDown={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel > 0 ? selectedPanel - 1 : selectedPanel
                      );
                    }
                  }}
                ></p>
              </div>
            );
          }}
          renderArrowNext={(onClickHandler, hasNext, label) => {
            const defStyle = { color: "white", cursor: "pointer" };
            const style = hasNext
              ? { ...defStyle, color: "black", fill: "black" }
              : { ...defStyle, color: "grey", fill: "grey" };
            return (
              <div className="no_margin ca_next">
                <svg
                  style={style}
                  tabIndex={0}
                  onClick={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel < panels.length - 1
                          ? selectedPanel + 1
                          : selectedPanel
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      !inactive &&
                      (e.key === "Enter" || e.key === "Space")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel < panels.length - 1
                          ? selectedPanel + 1
                          : selectedPanel
                      );
                    }
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="47.001"
                  height="47"
                  viewBox="0 0 47.001 47"
                >
                  <path
                    id="next"
                    d="M14.353,45.153a23.513,23.513,0,0,1,0-43.306A23.506,23.506,0,0,1,32.647,45.153a23.576,23.576,0,0,1-18.294,0Zm.933-41.1A21.177,21.177,0,1,0,23.5,2.4,21.036,21.036,0,0,0,15.286,4.056Zm1.5,28.724V14.22a1,1,0,0,1,1.507-.861l15.736,9.28a1,1,0,0,1,0,1.723l-15.736,9.28a.99.99,0,0,1-.506.14A1,1,0,0,1,16.786,32.78Z"
                  />
                </svg>
                <p
                  style={style}
                  onClick={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel < panels.length - 1
                          ? selectedPanel + 1
                          : selectedPanel
                      );
                    }
                  }}
                  onKeyDown={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setSelectedPanel((selectedPanel) =>
                        selectedPanel < panels.length - 1
                          ? selectedPanel + 1
                          : selectedPanel
                      );
                    }
                  }}
                ></p>
              </div>
            );
          }}
          {...getConfigurableProps()}
        >
          {panels}
        </Carousel>
      </div>
    </>
  );
};
export default InstructorCarousel;
