/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LearningComp from "../../components/Learningpath-config";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "../icons/deleteIcon.svg";
import { ReactComponent as AddIcon } from "../icons/addIcon.svg";
import { DialogConfirmation } from "../Instructor/dialog";
import { AlertColor } from "@mui/material";
import InstructorCarousel from "../../components/InstructorCarousel";
import axios from "../../axios/config";
import { StoreContext } from "../../data/store";
import AlertWindow from "../../components/Snackbar";
import {
  Properties,
  Learning_Journey,
  Panel,
  Panel_API_Data,
  Course_Activity,
} from "../../interfaces/Learning_Pathway_Interfaces";
import CourseActivity from "../../components/CourseActivity-config";
import Learner from "../Learner/learner.component";
import CourseCopyModal from "../../components/CourseCopyModal";
import { getCourseActivity, getLearningJourney } from "../../axios/api";
import CustomizedTables from "../../components/Doublebadge-table";
import TimerModal from "../../components/TimerModal";
import { getSession } from "../../utilities/querySession";

import { useTimer } from "react-timer-hook";

const Instructor: FC<unknown> = () => {
  const { state } = useContext<any>(StoreContext);
  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);
  /* Data State Variables required for API's */
  const [courseId, setCourseId] = useState<number>(-1);
  const [learningJourneyId, setLearningJourneyId] = useState<number>(-1);
  const [firstRender, setRender] = useState<boolean>(false);
  const [previewStatus, setPreviewStatus] = useState<boolean>(false);
  /*Page State Variables*/
  const [LearningJourney, setLearningJourney] = useState<any>();

  const [showCourseCopyModal, setShowCourseCopyModal] =
    useState<boolean>(false);

  const [copyCourses, setCopyCourses] = useState<[]>([]);
  /*Learning Journey State Variables*/
  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const [popUpAlert, setPopUpAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });
  const [popUpRefreshAlertOpen, setPopUpRefreshAlertOpen] =
    useState<boolean>(false);
  const [popUpRefreshAlert, setPopUpRefreshAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });
  const [panelAlertOpen, setPanelAlertOpen] = useState<boolean>(false);
  const [learningPathFormShow, setLearningPathFormShow] =
    useState<boolean>(true);
  const [learningWeekConfigShow, setLearningWeekConfigShow] =
    useState<boolean>(false);
  const DEFAULT_PANEL_PREFIX = "W";
  // const [hideConfigForm, setHideConfigForm] = useState<boolean>(true);
  const [activeTextbox, setActiveWeekConfig] = useState<boolean>(false);
  const [panelPrefix, setPanelPrefix] = useState<string>(DEFAULT_PANEL_PREFIX);
  const [isDisplayCalender, setIsDisplayCalender] = useState<boolean>(true);
  const [learnerDisplayCalender, setLearnerCalender] = useState<boolean>(false);
  const [addContShow, displayAddContent] = useState<boolean>(false);
  const [panelSaved, setPanelSaved] = useState<boolean>(false);
  const [selectedPanel, setSelectedPanel] = useState<number>(0);
  const [panelProperties, setPanelProperties] = useState<Properties[]>([]);
  const [open, setOpen] = React.useState(false);
  const [deleteItem, setDeleteItem] = useState<number>();
  const [samePanel, setSamePanel] = useState<boolean>();
  const [popUpTitle, setPopUpTitle] = useState("");
  const [popUpDescription, setPopUpDescription] = useState("");
  const [totalWeekPanels, setTotalWeekPanels] = useState(0);
  const [orientation, setOrientation] = useState(false);
  const [exam, setExam] = useState(true);
  const [hideLearningJourneySection, setHideLearningJourneySection] =
    useState<boolean>(true);
  const [hideAssessmentSection, setHideAssessmentSection] =
    useState<boolean>(true);
  const [hideActivityTimelineSection, setHideActivityTimelineSection] =
    useState<boolean>(true);
  const [isAutoPopulateDate, setIsAutoPopulateDate] = useState<boolean>(false);
  const [isUltraCourse, setIsUltraCourse] = useState<boolean>(false);
  const [isUltraCourseOverlay, setIsUltraCourseOverlay] = useState<boolean>(false);
  const [isUltraCourseMenu, setIsUltraCourseMenu] = useState<boolean>(false);
  const [panelCountStyle, setPanelCountStyle] = useState({
    border: "1px solid #707070",
  });
  /* Reference Variables */
  const panelCountRef = useRef<HTMLInputElement>(null);
  const blockCountInputRef = useRef<HTMLInputElement>(null);
  const examRef = useRef<HTMLInputElement>(null);
  const orientRef = useRef<HTMLInputElement>(null);
  const weekPanelGroup = useRef<HTMLUListElement>(null);
  /*Course Activity State Values*/
  const [courseActivityOpen, setCourseActivityOpen] = useState<boolean>(false);
  const [courseActivityTimeline, setCourseActivityTimeline] = useState<any>([]);
  const timelineOrientationRef = useRef<HTMLInputElement>(null);
  const timelineExamRef = useRef<HTMLInputElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [timelineStyle, setTimelineStyle] = useState({
    border: "1px solid #707070",
  });
  const [generateTimelineLoading, setGenerateTimelineLoading] =
    useState<boolean>(false);

  const [timelineOrientationCheckbox, setTimelineOrientationCheckbox] =
    useState<boolean>(false);
  const [timelineExamCheckbox, setTimelineExamCheckbox] =
    useState<boolean>(false);
  const [timeAlertOpen, setTimeAlertOpen] = React.useState<boolean>(false);

  const [refreshPage, setIsRefreshPage] = useState<boolean>(false);

  const [isChloeImported, setIsChloeImported] = useState<boolean>(false);

  const handleDisplayModal = async () => {
    setShowCourseCopyModal(true);
  };

  const [endTimer, setEndTimer] = useState<boolean>(false);
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false);
  const [endTimerModalContent, setEndTimerModalContent] = useState<string>("");

  const [sessionExpiryTime, setSessionExpiryTime] = useState<Date>(
    new Date(user.access_token_issued_time),
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const[errorOccured, setErrorOccured] = useState<boolean>(true);

  const maxCount = 40;

  
  const isUltraCourseStatus = async (courseId: string) => {
    const isUltraCourseStatus = await axios.get(
      `/api/instructor/isUltraCourse?userId=${user.user_id}&courseId=${courseId}`, {
        headers: {
          Authorization: getSession('token')
        }
      }
    );  
    return isUltraCourseStatus.data;
  };

  const getCourses = async () => {
    try {
      const apiData = (
        await axios.get(
          `/api/instructor/getCourse?course_id=${user.course_id}`,
          {
            headers: {
              Authorization: getSession("token"),
            },
          },
        )
      ).data;
      setCopyCourses(apiData);
    } catch (e) {
      setPopUpAlert({
        context: `Get Course API Returned Error!` + e,
        severity: `error`,
      });
    }
  };

  const fetchLearningJourney = async () => {
    try {
      const learningAPIResponse = await getLearningJourney(user.course_id);
      const learning_journeys = learningAPIResponse[0];
      setLearningJourney(learning_journeys);
      setLearningJourneyId(learning_journeys.learning_journey_id);
      setPanelPrefix(learning_journeys.panel_prefix);
      setIsDisplayCalender(learning_journeys.include_calender);
      setLearnerCalender(learning_journeys.display_calender);
      setIsAutoPopulateDate(learning_journeys.auto_populate);
      setIsUltraCourseOverlay(learning_journeys.uef_courseoverlay);
      setIsUltraCourseMenu(learning_journeys.uef_coursemenu);
      setCourseId(learning_journeys.course_id);
      setIsChloeImported(learning_journeys.is_imported);
      generatePanels(true, learning_journeys, learning_journeys.panels);
      setLearningPathFormShow(false);
      setLearningWeekConfigShow(true);
      let isUltra:boolean;
      isUltra = ( await isUltraCourseStatus(user.course_id) ==='Ultra') ? true : false;
      setIsUltraCourse(isUltra);
      if (
        learning_journeys.status === "true" ||
        learning_journeys.status === true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      setLearningPathFormShow(true);
      setLearningWeekConfigShow(false);
      if (err.message.includes("500")) {
        setPopUpAlert({
          context: `Existing learning pathway not found, please create new.`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
        return true;
      } else {
        setPopUpAlert({
          context: `Learning pathway API Returned Error!` + err,
          severity: `error`,
        });
        setPopUpAlertOpen(true);
        return true;
      }
    }
  };

  const fetchCourseActivityTimeline = async () => {
    try {
      const courseActivityAPIResponse = await getCourseActivity(user.course_id);
      const courseActivityTimelineData = courseActivityAPIResponse;
      setCourseActivityTimeline(courseActivityTimelineData);
      if (courseActivityTimelineData.Course_Activity_Panel) {
        setCourseActivityOpen(true);
      } else {
        setCourseActivityOpen(false);
      }
      if (
        courseActivityTimelineData.status === "true" ||
        courseActivityTimelineData.status === true
      ) {
        return true;
      } else if (!courseActivityTimelineData.Course_Activity_Panel) {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      if (err.message.includes("500")) {
        setPopUpAlert({
          context: `Existing Course Activity Not Found, please create new.`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
        return true;
      } else {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `error`,
        });
        setPopUpAlertOpen(true);
        return true;
      }
    }
  };

  const fetchTools = async () => {
    fetchLearningJourney();
    fetchCourseActivityTimeline();
  };

  const SessionExpiryTimer: FC<{ expiryTimestamp: Date }> = ({
    expiryTimestamp,
  }) => {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        setEndTimerValues();
      },
    });

    const IS_LESS_THAN_FIVE_MIN = hours === 0 && minutes <= 4 && seconds <= 59;

    const style = IS_LESS_THAN_FIVE_MIN
      ? { color: "red", fontWeight: "bold" }
      : { color: "#51247a", fontWeight: "bold" };

    return (
      <div className="timer">
        <div style={style}>
          <span>{hours < 10 ? "0" + hours : hours}</span>:
          <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
          <span>{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
      </div>
    );
  };

  const setEndTimerValues = () => {
    setEndTimer(true);
    setEndTimerModalContent(
      "Please refresh the page (or press F5) to continue.",
    );
    setShowTimerModal(true);
  };
  const dataValidation = () => {
    if(user?.course_id === undefined){
      setErrorOccured(true)
      setPopUpAlert({
        context: `Error Hai`,
        severity: `info`,
      });
      setPopUpAlertOpen(true);
    }
    else{
      setErrorOccured(false)
      setIsDisplayCalender(true);
      setPanelPrefix(DEFAULT_PANEL_PREFIX);
      setLearnerCalender(false);
      fetchTools();
      setRender(true);
      setShowCourseCopyModal(false);
      getCourses();
      handleSessionExpiry();
    }
  }

  useEffect(() => {
    dataValidation();
  }, []);

  useEffect(() => {
    if (refreshPage) {
      setPanelProperties([]);
      fetchTools();
      handleBacktoPanels();
      setIsDisplayCalender(true);
      setLearnerCalender(false);
      setPanelPrefix(DEFAULT_PANEL_PREFIX);
      fetchLearningJourney();
      fetchCourseActivityTimeline();
      setRender(true);
      setShowCourseCopyModal(false);
      getCourses();
      handleSessionExpiry();
      setIsRefreshPage(false);
      setIsChloeImported(true);
    }
  }, [refreshPage]);

  const handleSessionExpiry = () => {
    let time = new Date(user.access_token_issued_time);
    time = new Date(time.getTime() + 3600 * 1000);
    setSessionExpiryTime(time);
  };

  const onChangeChloeImported = (value: boolean) => {
    setIsChloeImported(value);
  };

  const showSavetoProceedError = () => {
    setPopUpAlert({
      context: `Please save your changes before proceeding !`,
      severity: `warning`,
    });
    setPopUpAlertOpen(true);
  };

  const handleAutoPopulate = async (e: any) => {
    var checked_status = e.target.checked;
    const data: {
      column: string;
      value: Boolean;
      learning_journey_id: Number;
    } = {
      column: "auto_populate",
      value: checked_status,
      learning_journey_id: learningJourneyId,
    };
    try {
      const status = (
        await axios.put(`/api/instructor/updateLearningJourney`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (status[0] === 1) {
        setIsAutoPopulateDate(checked_status);
      } else {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
      }
    } catch (err) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `info`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const handleUltraCourseOverlay = async (e: any) => {
    var checked_status = e.target.checked;
    const data: {
      column: string;
      value: Boolean;
      learning_journey_id: Number;
    } = {
      column: "uef_courseoverlay",
      value: checked_status,
      learning_journey_id: learningJourneyId,
    };
    try {
      const status = (
        await axios.put(`/api/instructor/updateLearningJourney`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (status[0] === 1) {
        setIsUltraCourseOverlay(checked_status);
      } else {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
      }
    } catch (err) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `info`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const handleUltraCourseMenu = async (e: any) => {
    var checked_status = e.target.checked;
    const data: {
      column: string;
      value: Boolean;
      learning_journey_id: Number;
    } = {
      column: "uef_coursemenu",
      value: checked_status,
      learning_journey_id: learningJourneyId,
    };
    try {
      const status = (
        await axios.put(`/api/instructor/updateLearningJourney`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (status[0] === 1) {
        setIsUltraCourseMenu(checked_status);
      } else {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
      }
    } catch (err) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `info`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const handleDisplayCalenderCheckbox = async (e: any) => {
    var checked_status = e.target.checked;
    const data: {
      column: string;
      value: Boolean;
      learning_journey_id: Number;
    } = {
      column: "display_calender",
      value: checked_status,
      learning_journey_id: learningJourneyId,
    };
    try {
      const status = (
        await axios.put(`/api/instructor/updateLearningJourney`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (status[0] === 1) {
        setLearnerCalender(checked_status);
      } else {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
      }
    } catch (err) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `info`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const handleGenerateCourseTimeline = async () => {
    if (generateTimelineLoading === false) {
      setGenerateTimelineLoading(true);
      const panelCount = +blockCountInputRef.current?.value!;
      const orientation = timelineOrientationRef.current?.checked ?? false;
      const examPeriod = timelineExamRef.current?.checked ?? false;
      if (panelCount < 1 || panelCount > 52) {
        setTimelineStyle({ border: "1px solid #FF0000" });
        setTimeAlertOpen(true);
        setGenerateTimelineLoading(false);
        return blockCountInputRef.current?.classList.add("empty");
      }
      const data: Course_Activity = {
        course_id: user.course_id,
        panel_count: panelCount,
        orientation: orientation,
        exam_period: examPeriod,
        status: false,
        user_id: user.user_id,
      };
      try {
        const panelData = (
          await axios.put(`/api/instructor/storeCourseActivity`, data, {
            headers: {
              Authorization: getSession("token"),
            },
          })
        ).data;
        setCourseActivityTimeline(await panelData);
        fetchCourseActivityTimeline();
        setGenerateTimelineLoading(false);
      } catch (err) {
        setPopUpAlert({
          context: `An error occurred. Please try again later!`,
          severity: `info`,
        });
        setPopUpAlertOpen(true);
        setGenerateTimelineLoading(false);
      }
    }
  };

  const handleActivityPanelChange = () => {
    fetchCourseActivityTimeline();
  };
  const storePanelAPI = async (props: Properties[]) => {
    let panelId;
    let panels: HTMLDivElement[] = [].slice.call(
      weekPanelGroup.current?.children,
    );
    let panelProps: Properties[] = props;
    if (
      courseId !== -1 &&
      learningJourneyId !== -1 &&
      learningJourneyId !== undefined
    ) {
      // if (fixIndex === true) {
      //   panelProps = panelProperties;
      //   panelProps = fixIndexinPanels(panelProps, panels);
      // }
      for (var i = 0; i < panelProps.length; i++) {
        const data: Panel_API_Data = {
          learning_journey_id: learningJourneyId,
          panel_name: panelProps[i].input,
          panel_colour: panelProps[i].color,
          panel_week_date: panelProps[i].date,
          panel_order: panelProps[i].index,
          is_published: panelProps[i].is_published,
        };
        if (panelProps[i].update === "N") {
          try {
            panelId = (
              await axios.put(`/api/instructor/storePanel`, data, {
                headers: {
                  Authorization: getSession("token"),
                },
              })
            ).data;

            if (panelId instanceof Array) {
              panelProps[i].panel_id = panelId[0].panel_id;
            } else {
              panelProps[i].panel_id = panelId.panel_id;
            }
            panelProps[i].update = "U";
          } catch (err) {
            return null;
          }
        } else if (panelProps[i].update === "U") {
          const data = {
            panel_id: panelProps[i].panel_id,
            panel_name: panelProps[i].input,
            panel_colour: panelProps[i].color,
            panel_week_date: panelProps[i].date,
            panel_order: panelProps[i].index,
            is_published: panelProps[i].is_published,
          };
          try {
            let panelUpdateStatus = (
              await axios.put(`/api/instructor/updatePanel`, data, {
                headers: {
                  Authorization: getSession("token"),
                },
              })
            ).data;

            if (panelUpdateStatus instanceof Array) {
              if (!panelUpdateStatus[0]) {
                return null;
              }
            } else {
              if (!panelUpdateStatus) {
                return null;
              }
            }
          } catch (err) {
            return null;
          }
        }
      }
    }
    return panelProps;
  };
  const handleAddContent = async (
    id: number,
    input?: string,
    color?: string,
  ) => {
    if (!activeTextbox) {
      let panels: HTMLDivElement[] = [].slice.call(
        weekPanelGroup.current?.children,
      );
      let panelProps: Properties[] = panelProperties;
      setSelectedPanel(panelProps.find((panel) => panel.id === +id)?.index!);
      if (
        courseId !== -1 &&
        learningJourneyId !== -1 &&
        learningJourneyId !== undefined
      ) {
        panelProps = (await storePanelAPI(panelProps)) ?? [];
        setPanelProperties([...panelProps]);
        setLearningWeekConfigShow(false);
        displayAddContent(true);
        setPanelSaved(true);
      } else if (user.roles.length === 0) {
        setPanelProperties([...panelProps]);
        setLearningWeekConfigShow(false);
        displayAddContent(true);
        setPanelSaved(true);
      } else {
      }
    } else {
      showSavetoProceedError();
    }
  };

  const handleAlertClose = (event: any, reason: string) => {
    if (reason !== `clickaway`) {
      setPopUpAlertOpen(false);
    }
  };
  const handleRefreshAlertClose = (event: any, reason: string) => {
    setPopUpRefreshAlertOpen(false);
    setIsRefreshPage(true);
  };

  const handleRefreshAlert = () => {
    handleBacktoPanels();
    setPopUpRefreshAlert({
      context: `An error occurred. Refreshing the page to get updated data`,
      severity: `error`,
    });
    setPopUpRefreshAlertOpen(true);
  };

  const hideLearningComponent = () => {
    setPopUpTexts(0);
    setLearningPathFormShow(true);
    setLearningWeekConfigShow(false);
  };

  const handleClickOpen = (item: number, samePanel?: boolean) => {
    if (open === false) {
      setPopUpTexts(item);
      setOpen(true);
      setDeleteItem(item);
      setSamePanel(samePanel);
    }
  };

  const fixIndex = (panelIndex: number, operation: '+'|'-') => {
    const panelProps: Properties[] = panelProperties;
    if(operation === '-' ){
      for (var index_min = panelIndex; index_min < panelProps.length; index_min++) {
        panelProps[index_min].index--;
      }
    }else if(operation === '+'){
      for(var index_plus = panelIndex; index_plus<panelProps.length; index_plus++){
        panelProps[index_plus].index++;
      }
    }
    return panelProps;
  }

  const removeItem = async (item: any) => {
    let panelProps = panelProperties;
    let panelIn = item;
    let date: Date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    );
    const switchToFormPage:boolean = panelProperties.length === 1;
    let panel = panelProps.find((prop:Properties) => prop.index === item);
    if (panel?.panel_id !== undefined) {
      try {
        let panelUpdateStatus = (
          await axios.delete(
            `/api/instructor/deletePanel?panel_id=${panel?.panel_id}`,
            {
              headers: {
                Authorization: getSession("token"),
              },
            },
          )
        ).data;
        if (panelIn >= 0) {
          panelProps.splice(panelIn, 1);
          panelProps = fixIndex(panelIn,'-');
          panelProps = setDates(date!, panelIn - 1, panelProps, true);
          setPanelProperties([...panelProps]);
          if (switchToFormPage) {
            handleDeleteLearningJourney();
            setTotalWeekPanels(0);
            hideLearningComponent();
            setIsDisplayCalender(true);
          }
        } else {
          setPopUpAlert({
            context: `An error occurred. Please try again later!`,
            severity: `error`,
          });
          setPopUpAlertOpen(true);
        }
      } catch (err: any) {
        setPopUpAlert({
          context: `Uncaught Error: ${err}`,
          severity: `error`,
        });
        setPopUpAlertOpen(true);
      }
    } else {
      if (panelIn >= 0) {
        panelProps.splice(panelIn, 1);
        panelProps = fixIndex(panelIn, '-');
        panelProps = setDates(date!, panelIn - 1, panelProps, true);
        setPanelProperties([...panelProps]);
        if (switchToFormPage) {
          handleDeleteLearningJourney();
          setTotalWeekPanels(0);
          hideLearningComponent();
          setIsDisplayCalender(true);
        }
      } else {
        setPopUpAlert({
          context: `An error occurred.  Please try again later`,
          severity: `error`,
        });
        setPopUpAlertOpen(true);
      }
    }
  };

  const onPanelContentChange = (field: string, value: any, id: number) => {
    let panelProps = panelProperties;
    setPanelSaved(false);
    let panel = panelProps.findIndex((item) => item?.id === id);

    switch (field) {
      case "input":
        panelProps[panel].input = value.toString().trim();
        break;
      case "color":
        panelProps[panel].color = value.toString();
        break;
      case "date":
        panelProps[panel].date = new Date(value);
        if (isAutoPopulateDate) {
          panelProps = setDates(value, panel, panelProps);
        }
        break;
      case "status":
        panelProps[panel].is_published = value;
        break;
      default:
        console.error("Error in Field !");
    }
    setPanelProperties([...panelProps]);
  };

  const setDates = (
    value: string | Date,
    panel: number,
    panelProps: Properties[],
    resetAllDates?: boolean,
  ) => {
    let tempPanelProps = panelProps;

    let tempDate: any = value;
    for (
      var currentPanelIndex = panel + 1;
      currentPanelIndex < tempPanelProps.length;
      currentPanelIndex++
    ) {
      if (resetAllDates) {
        tempPanelProps[currentPanelIndex].date = tempDate;
      } else {
        tempPanelProps[currentPanelIndex].date = tempDate;
        tempDate = new Date(tempDate.setDate(tempDate.getDate() + 7));
      }
    }
    return tempPanelProps;
  };

  const onActive = (status : boolean) =>{
    setActiveWeekConfig(status);
  }

  const addNewWeekItem = (item: number) => {
    setPanelSaved(false);
    // let newItems = items;
    let date: Date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    );
    let panelProps = panelProperties;
    panelProps.splice(item+1, 0, {
      id: totalWeekPanels + 1,
      color: "#51247A",
      date: date,
      input: "",
      is_published: true,
      index: item+1,
      panel_id: undefined,
      update: "N",
      isDisabled: false,
    });
    panelProps = fixIndex(item+2, '+');
    panelProps = setDates(date, item, panelProps, true);
    setPanelProperties([...panelProps]);
    setTotalWeekPanels(totalWeekPanels + 1);
  };

  const setPopUpTexts = (item: number) => {
    if (panelProperties.length === 1) {
      setPopUpTitle("Delete the last panel?");
      setPopUpDescription("Are you sure you want to delete the last panel?");
    } else if (panelProperties.length > 1) {
      setPopUpTitle("Delete the panel?");
      setPopUpDescription("Are you sure you want to delete this panel?");
    }

    if (item === -1) {
      setPopUpTitle("Clear all the panels?");
      setPopUpDescription("Are you sure you want to clear all the panels?");
    }
    if (item === -2) {
      setPopUpTitle("Clear all the Overview panels?");
      setPopUpDescription("Are you sure you want to clear all the panels?");
    }
  };

  const handleDeleteLearningJourney = async () => {
    try {
      let LJDeleteStatus = (
        await axios.delete(
          `/api/instructor/deleteLearningJourney?learning_journey_id=${learningJourneyId}`,
          {
            headers: {
              Authorization: getSession("token"),
            },
          },
        )
      ).data;
      setIsDisplayCalender(true);
      setLearnerCalender(false);
      setPanelPrefix(DEFAULT_PANEL_PREFIX);
    } catch (e) {
      setPopUpAlert({ context: "" + e, severity: `error` });
      setPopUpAlertOpen(true);
    }
  };

  const deleteCourseActivityTimeline = async () => {
    try {
      if (courseActivityTimeline.course_activity_id !== undefined) {
        let deleteStatus = (
          await axios.delete(
            `/api/instructor/deleteCourseActivity?course_activity_id=${courseActivityTimeline.course_activity_id}`,
            {
              headers: {
                Authorization: getSession("token"),
              },
            },
          )
        ).data;
        setTimelineOrientationCheckbox(false);
        setTimelineExamCheckbox(false);
        blockCountInputRef.current!.value! = "";
      }
    } catch (e) {
      setPopUpAlert({ context: "" + e, severity: `error` });
      setPopUpAlertOpen(true);
    }
  };

  const handlePanelClose = (result: Boolean) => {
    if (result) {
      deleteCourseActivityTimeline();
      setOpenPanel(false);
      setCourseActivityOpen(false);
      setCourseActivityTimeline([]);
    }
    setOpenPanel(false);
  };

  const handleClearActivityTimeline = (item: number) => {
    if (openPanel === false) {
      setPopUpTexts(item);
      setOpenPanel(true);
    }
  };

  const handleClose = (result: Boolean) => {
    if (result) {
      setPanelSaved(false);
      if (deleteItem === -1) {
        handleDeleteLearningJourney();
        hideLearningComponent();
        setTotalWeekPanels(0);
        setPanelProperties([]);
        document.getElementsByTagName("body")[0].classList.remove("ptr_none");
        setOpen(false);
        setIsDisplayCalender(true);
        return;
      }
      removeItem(deleteItem);
      if (samePanel) {
        document.getElementsByTagName("body")[0].classList.remove("ptr_none");
      }
    }
    setOpen(false);
  };

  const checkPanelInput = useCallback(async () => {
    let num: number = +(panelCountRef.current?.value ?? -1);
    if (num < 1 || num > maxCount) {
      setPanelCountStyle({ border: "1px solid #FF0000" });
      return false;
    }
    return true;
  }, [maxCount]);

  const generatePanels = async (
    foundApi: boolean = false,
    learningJourney?: Learning_Journey,
    panel?: Panel[],
  ) => {
    let element: JSX.Element[] = [];
    let panelProps: Properties[] = [];
    panel?.sort((a, b) =>
      a.panel_order! > b.panel_order!
        ? 1
        : b.panel_order! > a.panel_order!
        ? -1
        : 0,
    );

    if (!panelPrefix) {
      setPanelPrefix(DEFAULT_PANEL_PREFIX);
    }

    const generateNewPanel = (
      i: number,
      value: string,
      index: number,
      color?: string,
      date?: string | Date,
      panel_id?: number,
      update?: "U" | "N" | "D",
      is_published?: boolean | false,
    ) => {
      let dateVar = date;
      if (dateVar !== undefined) {
        dateVar = new Date(dateVar);
      } else {
        dateVar = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
        );
      }

      panelProps.push({
        id: i,
        panel_id: panel_id ?? undefined,
        input: value,
        is_published: is_published ?? false,
        color: color ?? "#51247A",
        date: dateVar,
        index: index,
        update: update ?? "N",
        isDisabled: true,
      });
    };
    if (foundApi) {
      if (
        learningJourney !== undefined &&
        panel !== undefined &&
        panel.length !== 0
      ) {
        setPanelSaved(true);
        for (var j = 0; j < panel!.length; j++) {
          generateNewPanel(
            j,
            panel[j].panel_name,
            j,
            panel[j].panel_colour,
            panel[j].panel_week_date,
            panel[j].panel_id,
            "U",
            panel[j].is_published
          );
        }
        // setItems(element);
        setPanelProperties(panelProps);
        setTotalWeekPanels(panel.length);
        return true;
      } else if (
        learningJourney !== undefined &&
        panel !== undefined &&
        panel.length === 0
      ) {
        setPanelSaved(false);
        let num: number = learningJourney.panel_count;
        let orientation: boolean = learningJourney.orientation;
        let exam: boolean = learningJourney.exam_period;
        setOrientation(orientation);
        setExam(exam);
        let countOfPanels = num;
        if (num > 0 && num <= maxCount && Number.isInteger(num)) {
          if (orientation) {
            generateNewPanel(
              0,
              "Orientation",
              0,
              undefined,
              undefined,
              undefined,
              undefined,
              true,
            );
            countOfPanels += 1;
          }
          for (var k = orientation? 1:0; k < (orientation?num + 1:num); k++) {
            const start_point = orientation? 0:1; 
            generateNewPanel(
              k,
              (learningJourney.panel_prefix || DEFAULT_PANEL_PREFIX) +
                "-" +
                ((start_point+k) < 10 ? "0" : "") +
                (start_point+k),
              k,
              undefined,
              undefined,
              undefined,
              undefined,
              true,
            );
          }
          if (exam) {
            generateNewPanel(
              k,
              "Exam Preparation",
              k,
              undefined,
              undefined,
              undefined,
              undefined,
              true,
            );
            countOfPanels += 1;
          }
          // setItems(element);
          setPanelProperties(panelProps);
          setTotalWeekPanels(panelProps.length);
          return true;
        }
      } else {
        setPopUpAlert({
          context: "Generation of Panels have failed, please try again later",
          severity: "error",
        });
        setPopUpAlertOpen(true);
        return false;
      }
    } else {
      setPanelSaved(false);
      let num: number = +(panelCountRef.current?.value ?? -1);
      let orientation: boolean = orientRef.current?.checked ?? false;
      let exam: boolean = examRef.current?.checked ?? false;

      setOrientation(orientation);
      setExam(exam);
      let countOfPanels = num;
      if (await checkPanelInput()) {
        try {
          const data: Learning_Journey = {
            user_id: user.user_id,
            panel_prefix:
              panelPrefix.trim() !== ""
                ? panelPrefix.trim()
                : DEFAULT_PANEL_PREFIX,
            include_calender: true,
            display_calender: false,
            auto_populate: false,
            panel_count: num,
            course_id: user.course_id,
            orientation: orientation,
            exam_period: exam,
            isPublished: true,
            status: false,
          };
          const LJPayload = (
            await axios.put(`/api/instructor/storeLearningJourney`, data, {
              headers: {
                Authorization: getSession("token"),
              },
            })
          ).data;
          if (LJPayload instanceof Array) {
            setLearningJourneyId(LJPayload[0].learning_journey_id);
            setCourseId(LJPayload[0].course_id);
          } else {
            setLearningJourneyId(LJPayload.learning_journey_id);
            setCourseId(LJPayload.course_id);
          }
        } catch (e) {
          setPopUpAlert({
            context: `An error occurred. Please try again later!`,
            severity: `error`,
          });
          setPopUpAlertOpen(true);
        }
        fetchLearningJourney();
      } else {
        document.getElementById("panels_count")?.classList.add("error");
        // Add Here
        setPanelAlertOpen(true);
        return;
      }
    }
  };

  const handleBacktoPanels = () => {
    setLearningWeekConfigShow(true);
    displayAddContent(false);
  };

  const generateDashboard = async () => {
    let data = {
      course_id: user.course_id,
      user_id: user.user_id,
      learning_journey_id: learningJourneyId,
      activity_timeline_id: courseActivityTimeline.course_activity_id,
      status: true,
    };
    try {
      const LJStatusUpdate = (
        await axios.put(`/api/instructor/dashboard`, data, {
          headers: {
            Authorization: getSession("token"),
          },
        })
      ).data;
      if (LJStatusUpdate[0] === 1 || LJStatusUpdate === 1) {
        setPopUpAlert({
          context: `Dashboard is generated successfully !`,
          severity: "success",
        });
        setPopUpAlertOpen(true);
      } else {
        setPopUpAlert({
          context: `Dashboard is not Generated, Try Agaim!`,
          severity: "error",
        });
        setPopUpAlertOpen(true);
      }
    } catch (e) {
      setPopUpAlert({
        context: `Dashboard generation Failed !` + e,
        severity: "error",
      });
      setPopUpAlertOpen(true);
    }
  };
  const handlePreviewBtn = async (status: boolean) => {
    if (learningWeekConfigShow || addContShow) {
      if ((await storePanelAPI(panelProperties)) !== null) {
        setPreviewStatus(status);
      }
    } else {
      setPreviewStatus(status);
    }
  };

  const handleOnChange = (value: boolean) => {
    setShowCourseCopyModal(false);
    setIsRefreshPage(value);
  };

  const showError = () => {
    setPopUpAlert({
      context: `An error occurred.  Please refresh and try again later`,
      severity: `error`,
    });
    setPopUpAlertOpen(true);
  };
  return errorOccured ? (
    <>
      <h2>Please refresh (F5) the page, thank you.</h2>
    </>
  ) : (
    <div className="App">
      <CourseCopyModal
        showCourseCopyModal={showCourseCopyModal}
        onChange={(refreshPage: boolean) => handleOnChange(refreshPage)}
        user={user}
        copyCourseList={copyCourses}
      />
      <TimerModal
        showTimerModal={showTimerModal}
        endTimer={endTimer}
        content={endTimerModalContent}
      />

      {/* Snackbar Alert for Number of Panels */}
      <AlertWindow
        popUpAlertOpen={popUpAlertOpen}
        handleAlertClose={handleAlertClose}
        popUpAlert={popUpAlert}
      />
      <AlertWindow
        popUpAlertOpen={popUpRefreshAlertOpen}
        handleAlertClose={handleRefreshAlertClose}
        popUpAlert={popUpRefreshAlert}
      />
      <div className="instructor_banner">
        <div className="banner_header">Learning Pathway Settings</div>
        <div className={editMode ? "button_class" : "hidden"}>
          {copyCourses.length > 0 && (
            <button
              className="course_copy_button" //courseActivityOpen will be used here.
              onClick={() => handleDisplayModal()}
            >
              Import Chloe
            </button>
          )}

          {(learningWeekConfigShow || addContShow || courseActivityOpen) && (
            <button
              className={"course_copy_button"}
              onClick={async () => {
                if ((await storePanelAPI(panelProperties)) !== null) {
                  generateDashboard();
                }
              }}
            >
              Publish Dashboard
            </button>
          )}
          {previewStatus ? (
            <button
              className="course_copy_button"
              onClick={(e) => handlePreviewBtn(false)}
            >
              Exit Preview
            </button>
          ) : (
            <button
              className={
                courseActivityOpen || learningWeekConfigShow || addContShow
                  ? "course_copy_button"
                  : "hidden"
              } //courseActivityOpen will be used here.
              onClick={(e) => handlePreviewBtn(true)}
            >
              Preview
            </button>
          )}
        </div>
        {editMode ? (
          <button
            className={!previewStatus ? "preview_button" : "hidden"}
            onClick={(e) => {
              setEditMode(false);
            }}
          >
            Exit Edit Mode
          </button>
        ) : (
          <button
            className="preview_button"
            onClick={(e) => {
              setEditMode(true);
            }}
          >
            Enter Edit Mode
          </button>
        )}
      </div>

      <div className={!previewStatus && editMode ? "" : "hidden"}>
        <SessionExpiryTimer expiryTimestamp={sessionExpiryTime} />
        {/* Instructor View Starts Here */}
        {/* Learning journey Starts */}
        <div className="section learningcomp">
          <div className="section_doublebadge">
            <section className="flex align-items-center heading__learn">
              <h1 className="section__h1">Assessment</h1>
              <svg
                className={
                  hideAssessmentSection
                    ? "arrow__down"
                    : "arrow_down svg_rotated"
                }
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
              >
                <path
                  id="polygon_16"
                  data-name="Polygon 16"
                  d="M7,0l7,8H0Z"
                  transform="translate(14 8) rotate(180)"
                  fill="#1a1a1a"
                  onClick={() => {
                    setHideAssessmentSection(!hideAssessmentSection);
                  }}
                  className="arrow__down_path"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space")
                      setHideAssessmentSection(!hideAssessmentSection);
                  }}
                />
              </svg>
            </section>
            <div style={{ display: hideAssessmentSection ? "block" : "none" }}>
              <CustomizedTables />
            </div>
          </div>
          {/* Learning Journey Header */}
          <div className="learning_journey-wrap">
            <div className="flex heading__learn align-items-center">
              <section className="section_flex ">
                <h1 className="section__h1">Learning Pathway</h1>
                <svg
                  className={
                    hideLearningJourneySection
                      ? "arrow__down"
                      : "arrow_down svg_rotated"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                >
                  <path
                    id="polygon_16"
                    data-name="Polygon 16"
                    d="M7,0l7,8H0Z"
                    transform="translate(14 8) rotate(180)"
                    fill="#1a1a1a"
                    onClick={() => {
                      setHideLearningJourneySection(
                        !hideLearningJourneySection
                      );
                    }}
                    className="arrow__down_path"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Space")
                        setHideLearningJourneySection(
                          !hideLearningJourneySection
                        );
                    }}
                  />
                </svg>
              </section>
              {addContShow && (
                <button
                  className={"backto_panel-button ms-auto float-end "}
                  onClick={() => {
                    if (
                      document.activeElement !==
                      document.querySelector("input:focus")
                    ) {
                      setIsRefreshPage(true);
                      handleBacktoPanels();
                    }
                  }}
                >
                  Edit Panels
                </button>
              )}
            </div>
            {/* Learning pathway Dynamic Panel Block */}

            <div
              id="hide__form"
              style={{ display: hideLearningJourneySection ? "block" : "none" }}
            >
              {learningPathFormShow && (
                <div
                  id="learning_path-select-values"
                  // className={learningPathFormShow ? "" : "hidden"}
                >
                  <div>
                    <form className="lj_config_container">
                      <section className="d-flex flex-column align-items-start">
                        <section className="section_flex">
                          <label
                            htmlFor="panels_count"
                            className="label__total"
                          >
                            Panel Name Prefix{" "}
                          </label>

                          <section className="sub_section_flex">
                            <input
                              id="panels_prefix"
                              className="text__input"
                              name="panels_count"
                              autoComplete="off"
                              // defaultValue={panelPrefix}
                              onChange={(event) => {
                                setPanelPrefix(event.target.value);
                              }}
                            />
                          </section>
                        </section>
                        <section className="section_flex_1 padding_top_20">
                          <label
                            htmlFor="panels_count"
                            className="label__total"
                          >
                            Total Number of Panels
                            <sup>
                              <b>*</b>
                            </sup>
                          </label>
                          <input
                            type="number"
                            ref={panelCountRef}
                            style={panelCountStyle}
                            id="panels_count"
                            className="number__input"
                            name="panels_count"
                            min={0}
                            max={maxCount}
                            autoComplete="off"
                            onInput={() => {
                              document
                                .getElementById("panels_count")
                                ?.classList.remove("error");
                              setPanelAlertOpen(false);
                            }}
                            onChange={() => {
                              if (
                                panelCountRef.current?.value !== undefined &&
                                panelCountRef.current.value !== ""
                              ) {
                                if (
                                  +panelCountRef.current.value <= 0 ||
                                  +panelCountRef.current.value >= 27
                                ) {
                                  document
                                    .getElementById("panels_count")
                                    ?.classList.add("error");
                                } else {
                                  setPanelCountStyle({
                                    border: "1px solid #707070",
                                  });
                                }
                              }
                            }}
                            onKeyDown={(event) => {
                              let key = event.key;

                              if (key === "Backspace" || key === "Delete") {
                                return;
                              }

                              if (key === ".") {
                                event.preventDefault();
                                return;
                              }
                            }}
                          />
                        </section>
                        <label
                          style={{
                            display: panelAlertOpen ? "block" : "none",
                            color: "#FF0000",
                            marginLeft: "224px",
                            fontWeight: "normal",
                          }}
                        >
                          Enter a value between 1 and {maxCount}!
                        </label>
                        <section className="section_flex_1 padding_top_25">
                          <label
                            htmlFor="week_auto_populate"
                            className="d-flex flex-row align-items-center checkbox_container"
                          >
                            <input
                              ref={orientRef}
                              type="checkbox"
                              id="include_orientation"
                              className="week_config_populate-checkbox"
                              name="week_auto_populate"
                            />
                            <span className="checkmark"></span>
                            <label
                              htmlFor="include_orientation"
                              className="week_config-checkbox-label"
                            >
                              Include Orientation
                            </label>
                          </label>

                          <label
                            htmlFor="week_auto_populate"
                            className="d-flex flex-row align-items-center checkbox_container"
                          >
                            <input
                              type="checkbox"
                              ref={examRef}
                              id="include_exam"
                              className="week_config_populate-checkbox"
                              name="week_auto_populate"
                            />
                            <span className="checkmark"></span>
                            <label
                              htmlFor="include_exam"
                              className="week_config-checkbox-label"
                            >
                              Include Exam Period
                            </label>
                          </label>
                        </section>
                      </section>
                    </form>
                  </div>
                  <button
                    onClick={async () => {
                      generatePanels(false);
                    }}
                    className="learning_path-generate_button"
                  >
                    Generate Panels
                  </button>
                </div>
              )}

              {/*Start Of Week-Panel-Configuration*/}
              <div
                id="learning_path-week-config"
                className={learningWeekConfigShow ? "" : "hidden"}
              >
                <div className="section week_config-comp">
                  <section className="section_flex_3 ">
                    <div className={isDisplayCalender ? "" : "hidden"}>
                      <label
                        htmlFor="display_calender"
                        className="d-flex flex-row checkbox_container"
                      >
                        <input
                          checked={learnerDisplayCalender}
                          type="checkbox"
                          id="display_calender"
                          className="week_config_populate-checkbox"
                          name="display_calender"
                          onChange={(e) => handleDisplayCalenderCheckbox(e)}
                        />
                        <span className="checkmark"></span>
                        <label
                          htmlFor="display_calender"
                          className="week_config-checkbox-label"
                        >
                          Display Calendar
                        </label>
                      </label>
                    </div>

                    <div className={isDisplayCalender ? "" : "hidden"}>
                      <label
                        htmlFor="week_auto_populate"
                        className="d-flex flex-row checkbox_container"
                      >
                        <input
                          checked={isAutoPopulateDate}
                          type="checkbox"
                          id="auto_populate_panel_dates"
                          className="week_config_populate-checkbox"
                          name="week_auto_populate"
                          onClick={(e: any) => {
                            handleAutoPopulate(e);
                          }}
                        />
                        <span className="checkmark"></span>
                        <label
                          htmlFor="auto_populate_panel_dates"
                          className="week_config-checkbox-label"
                        >
                          Auto-populate dates by week
                        </label>
                      </label>
                    </div>

                    <div className={isUltraCourse ? "" : "hidden"}>
                      <label
                        htmlFor="week_course_overlay"
                        className="d-flex flex-row checkbox_container"
                      >
                        <input
                          checked={isUltraCourseOverlay}
                          type="checkbox"
                          id="ultra_courseoverlay"
                          className="week_config_populate-checkbox"
                          name="week_overlay_populate"
                          onClick={(e: any) => {
                            handleUltraCourseOverlay(e);
                          }}
                        />
                        <span className="checkmark"></span>
                        <label
                          htmlFor="ultra_courseoverlay"
                          className="week_config-checkbox-label"
                        >
                          Enable Course Overlay
                        </label>
                      </label>
                    </div>

                    <div className={isUltraCourse ? "" : "hidden"}>
                      <label
                        htmlFor="week_course_menu"
                        className="d-flex flex-row checkbox_container"
                      >
                        <input
                          checked={isUltraCourseMenu}
                          type="checkbox"
                          id="ultra_coursemenu"
                          className="week_config_populate-checkbox"
                          name="week_menu_populate"
                          onClick={(e: any) => {
                            handleUltraCourseMenu(e);
                          }}
                        />
                        <span className="checkmark"></span>
                        <label
                          htmlFor="ultra_coursemenu"
                          className="week_config-checkbox-label"
                        >
                          Enable Course Menu Link
                        </label>
                      </label>
                    </div>
                  </section>

                  <ul
                    ref={weekPanelGroup}
                    className="week_config-list list-group"
                  >
                    {panelProperties
                      .sort((a: Properties, b: Properties) => a.index - b.index)
                      .map((item: Properties, key) => {
                        return (
                          <div
                            key={item.id}
                            id={`${item.id}`}
                            data-index={key}
                            className="learning__config-panel-parent"
                          >
                            <li
                              id={`${item.id}`}
                              className="week_config-item list-group-item"
                            >
                              <div
                                id={`${item.id}`}
                                className="learning__config-panel"
                              >
                                <LearningComp
                                  id={item.id}
                                  value={item.input ?? ""}
                                  isAutoPopulateDate={isAutoPopulateDate}
                                  isDisabled={item.isDisabled}
                                  showCalender={true}
                                  isUltraCourseOverlay={isUltraCourseOverlay}
                                  isUltraCourseMenu={isUltraCourseMenu}
                                  date={item.date}
                                  color={item.color ?? "#51247A"}
                                  onChange={onPanelContentChange}
                                  isPublished={item.is_published}
                                  showSavetoProceedError={
                                    showSavetoProceedError
                                  }
                                  activeWeekConfig={activeTextbox}
                                  onActive={onActive}
                                />
                              </div>
                            </li>
                            <button
                              className="learning_config-button margin_left-30"
                              onClick={() => {
                                handleAddContent(item.id ?? 0);
                              }}
                            >
                              Add Content
                            </button>
                            <IconButton
                              style={{ marginLeft: "30px" }}
                              className="ptr_all"
                              component="span"
                              onClick={() => {
                                handleClickOpen(item.index, true);
                                setActiveWeekConfig(false);
                              }}
                            >
                              <DeleteIcon title="Delete" />
                            </IconButton>
                            <IconButton
                              style={{ marginLeft: "30px" }}
                              component="span"
                              onClick={() => {
                                if (!activeTextbox) {
                                  addNewWeekItem(key);
                                }
                              }}
                            >
                              <AddIcon title="Add" />
                            </IconButton>
                          </div>
                        );
                      })}
                  </ul>
                </div>
                <button
                  className="disabled learning_config-button "
                  onClick={() => {
                    handleClickOpen(-1, true);
                    setActiveWeekConfig(false);
                  }}
                >
                  Clear All Panels
                </button>
              </div>
              {/*Start of Add-Contents to Panel*/}
              <div className={addContShow ? "" : "hidden"}>
                <InstructorCarousel
                  panelProp={panelProperties}
                  selectedPanel={selectedPanel}
                  panels={LearningJourney?.panels}
                  showError={showError}
                  showSavetoProceedError={showSavetoProceedError}
                  showRefreshError={handleRefreshAlert}
                  isChloeImported={isChloeImported}
                  onChangeChloeImported={onChangeChloeImported}
                />
              </div>
            </div>
          </div>
        </div>
        {/* COURSE ACTIVITY OVERVIEW */}
        <div className="outer_wrap">
          <div className="flex heading__learn align-items-center">
            <section className="section_flex_2 ">
              <h1 className="section__h2">Activity Overview</h1>
              <svg
                className={
                  hideActivityTimelineSection
                    ? "arrow__down__2"
                    : "arrow__down__2 svg_rotated"
                }
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
              >
                <path
                  id="polygon_16"
                  data-name="Polygon 16"
                  d="M7,0l7,8H0Z"
                  transform="translate(14 8) rotate(180)"
                  fill="#1a1a1a"
                  onClick={() => {
                    setHideActivityTimelineSection(
                      !hideActivityTimelineSection
                    );
                  }}
                  className="arrow__down_path"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space")
                      setHideActivityTimelineSection(
                        !hideActivityTimelineSection
                      );
                  }}
                />
              </svg>
            </section>
          </div>
          <div
            id="hide__form_activity"
            className="course_activity_outer_wrap"
            style={{ display: hideActivityTimelineSection ? "block" : "none" }}
          >
            <div
              id="activity-timeline-select-values"
              className={courseActivityOpen ? "hidden" : ""}
            >
              <form className="lj_config_container_2">
                <section className="d-flex align-items-center">
                  <section className="section_flex ">
                    <label className="label__total__2">
                      Total Number of Panels
                      <sup>
                        <b>*</b>
                      </sup>
                    </label>
                    <div className="timeline_div">
                      <input
                        ref={blockCountInputRef}
                        style={timelineStyle}
                        id="blocks_count"
                        className="no-spin timeline_number__input"
                        type="number"
                        name="blocks_count"
                        autoComplete="off"
                        max={52}
                        min={1}
                        onInput={() => {
                          blockCountInputRef.current?.classList.remove("error");
                        }}
                        onChange={() => {
                          if (
                            blockCountInputRef.current?.value !== undefined &&
                            blockCountInputRef.current.value !== ""
                          ) {
                            setTimeAlertOpen(false);
                            setTimelineStyle({ border: "1px solid #707070" });
                          }
                        }}
                        onKeyDown={(event) => {
                          let key = event.key;
                          if (key === "Backspace" || key === "Delete") {
                            return;
                          } else if (key === ".") {
                            event.preventDefault();
                            return;
                          }
                        }}
                      />
                      <label
                        style={{
                          display: timeAlertOpen ? "block" : "none",
                          color: "#FF0000",
                        }}
                      >
                        Enter a value between 1 and 52 !
                      </label>
                    </div>
                  </section>
                </section>
                <div className="d-flex">
                  <label className="container_exam_orientaion">
                    Include Orientation
                    <input
                      ref={timelineOrientationRef}
                      type="checkbox"
                      checked={timelineOrientationCheckbox}
                      onChange={(e) =>
                        setTimelineOrientationCheckbox(e.target.checked)
                      }
                    ></input>
                    <span className="checkmark_check"></span>
                  </label>

                  <label className="container_exam_orientaion">
                    Include Exam Period
                    <input
                      ref={timelineExamRef}
                      type="checkbox"
                      checked={timelineExamCheckbox}
                      onChange={(e) =>
                        setTimelineExamCheckbox(e.target.checked)
                      }
                    ></input>
                    <span className="checkmark_check"></span>
                  </label>
                </div>
              </form>
              <button
                className="learning_path-generate_button_2"
                onClick={() => {
                  if (!activeTextbox) {
                    handleGenerateCourseTimeline();
                  } else {
                    showSavetoProceedError();
                  }
                }}
              >
                <div style={{ cursor: "pointer" }}>Generate</div>
              </button>
            </div>

            {courseActivityOpen && (
              <div
                className={
                  courseActivityOpen
                    ? "course_activity_container"
                    : "course_activity_container hidden"
                }
              >
                <div className="course_activity_panel_container">
                  <CourseActivity
                    courseActivityTimelineData={courseActivityTimeline}
                    onChange={handleActivityPanelChange}
                    showRefreshError={handleRefreshAlert}
                  />
                </div>
                <button
                  className="disabled learning_config-button "
                  onClick={() => {
                    handleClearActivityTimeline(-2);
                    // deleteCourseActivityTimeline();
                  }}
                >
                  Clear All Overview Panels
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "130px" }}>
        {(previewStatus || !editMode) && (
          <Learner learningPreview={editMode ? true : undefined} />
        )}
      </div>

      {/* Dialogue Confirmation for Deletion of Panels and Clearing of Panels of Learning Journey */}
      <DialogConfirmation
        title={popUpTitle}
        description={popUpDescription}
        handleClose={handleClose}
        open={open}
      />

      <DialogConfirmation
        title={popUpTitle}
        description={"Are you sure you want to clear all the Overview panels?"}
        handleClose={handlePanelClose}
        open={openPanel}
      />
    </div>
  );
};
export default Instructor;
